// import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AttendanceModule } from './attendance/attendance.module';
import { LoginComponent } from './login/login.component';
import { LoginService } from './login/login.service';
import { DashboardModule } from './dashboard/dashboard.module';
import { ContractorModule } from './contractor/contractor.module';
import { LabourModule } from './labour/labour.module';
import { CalendarModule } from './calendar/calendar.module';
import { UserManagementModule } from './user-management/user-management.module';
import { GrievanceModule } from './grievance/grievance.module';
import { HrClearanceModule } from './hr-clearance/hr-clearance.module';
import { ReportModule } from './report/report.module';
import { SidebarComponent, SidebarService, FooterComponent, HeaderComponent, Ng4LoadingSpinnerComponent } from './core/components';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { YearlyBudgetModule } from './yearly-budget/yearly-budget.module';
import { ManPowerModule } from './man-power/man-power.module';
import { ManPowerYearlyModule } from './man-power-yearly/man-power-yearly.module';
import { HttpConfigInterceptor } from './http.interceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ShiftModule } from './shift/shift.module';
import { MonthlyAttendanceReportComponent } from './monthly-attendance-report/monthly-attendance-report.component';
import { SharedService } from './core/services';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
// import { CommunicationModule } from './communication/communication.module';
import { JwtInterceptor } from './_helper';
import { NotifyService } from './common/notify.service';
// import { CommonService } from './common/common.service';
// tslint:disable-next-line: max-line-length
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ImportExcelModule } from './import-excel/import-excel.module';
import { LeaveModule } from './leave-module/leave.module';
import { VisitorManagementModule } from './visitor-management/visitor-management.module';
// import { SessionExpirationAlert, SessionInteruptService } from 'session-expiration-alert';
// import { AppSessionInteruptService } from './services/app-session-interupt.service';
import { RecaptchaModule } from 'ng-recaptcha';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotPasswordService } from './forgot-password/forgot-password.service';
import { PolicyModule } from './policy/policy.module';
import { FaceRecognitionUIModule } from './face-recognition/faceRecognitionUI.module';
import { CoffApprovalProcessModule } from './coff-approval-process/coff-approval-process.module';
import { AgTableComponent } from './sample/ag-table/ag-table.component';
import { Table1Component } from './sample/ag-table/table1/table1.component';
import { Table2Component } from './sample/ag-table/table2/table2.component';
import { Table3Component } from './sample/ag-table/table3/table3.component';
import { Table4Component } from './sample/ag-table/table4/table4.component';
import { Table5Component } from './sample/ag-table/table5/table5.component';
import { SampleTableComponent } from './sample/sample-table.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    Ng4LoadingSpinnerComponent,
    AppComponent,
    LoginComponent,
    SidebarComponent,
    MonthlyAttendanceReportComponent,
    ForgotPasswordComponent,
    AgTableComponent,
    Table1Component,
    Table2Component,
    Table3Component,
    Table4Component,
    Table5Component,
    SampleTableComponent,
    PageNotFoundComponent
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule.forRoot(),
    DashboardModule,
    LabourModule,
    ContractorModule,
    LabourModule,
    CalendarModule,
    HrClearanceModule,
    GrievanceModule,
    ShiftModule,
    AttendanceModule,
    UserManagementModule,
    YearlyBudgetModule,
    ManPowerModule,
    ManPowerYearlyModule,
    ReportModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    ImportExcelModule, // SessionExpirationAlertModule.forRoot()
    LeaveModule,
    VisitorManagementModule,
    RecaptchaModule,
    PolicyModule,
    FaceRecognitionUIModule,
    CoffApprovalProcessModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    { provide: 'Window', useValue: window },
    LoginService,
    SidebarService,
    SharedService,
    NotifyService,
    ForgotPasswordService,
  ],
  
  bootstrap: [
    AppComponent
  ],

})

export class AppModule { }
