import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthService } from './service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    if (this.authService.isAuthenticatedUser()) {
      
      if(this.authService.hasPermission(state.url)) {
        return true;
      } else {
        return this.router.createUrlTree(['/not-found']);
      }

    } else {
      // Redirect to the login page or any other desired route
      return this.router.createUrlTree(['/login']);
    }
  }
}