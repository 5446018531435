import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from '../../app.globals';
declare var $: any;
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContractorAdapter } from '../contractor-adapter/contractor.adpater';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class LicencesService {
  constructor(private http: HttpClient,
    private contractorAdapter: ContractorAdapter) { }

  /**
   * @description used to get all the work order.
   * @author Sonal Prajapati
   * @param contractorType a number type of the contractor.
   */
  getLicenses(contractorType: number) {
    const userId = localStorage.getItem('UserID');
    const queryString = $.param({
      userid: localStorage.getItem('UserID'),
      // tslint:disable-next-line: object-shorthand-properties-first
      contractorType
    });

    let payload = {
      search: null,
      sDate: null,
      eDate: null,
      selectedField: null,
      contractor_Code: null,
      UserId: localStorage.getItem('UserID'),
      // RoleCode: JSON.parse(localStorage.getItem('Role'))[0].Role_Code,
      Plant_Code : null,
      filterType: null,
      condition: null,
      State_Code: null,            
      Status_Code: null,
      IsActive: null,
      NatureOfLicense_Code: null,
      NatureOfWork_Code: null,
      TotalAccupied: null,        
      Contractor_Code: null,
      SubContractor_Code: null,
      WorkOrder_Code: null,
      SubWorkOrder_Code: null,
      isContractorType: contractorType,
    }
    // const url = `${GLOBAL.APIS.LICENSE.GET_LICENSE}?search=&sDate=&eDate=&selectedField=&filterType=&condition=&State_Code=
    //             &Status_Code=&Plant_Code=&IsActive=&NatureOfLicense_Code=&NatureOfWork_Code=&TotalAccupied=
    //             &Contractor_Code=&SubContractor_Code=&WorkOrder_Code=&SubWorkOrder_Code=&isContractorType=
    //             ${contractorType}&UserId=${userId}`;
    // const url = `${GLOBAL.APIS.LICENSE.SP_LISTING_LICENSE_FILTER}?search=&sDate=&eDate=&selectedField=&filterType=&condition=&State_Code=
    // &Status_Code=&Plant_Code=&IsActive=&NatureOfLicense_Code=&NatureOfWork_Code=&TotalAccupied=
    // &Contractor_Code=&SubContractor_Code=&WorkOrder_Code=&SubWorkOrder_Code=&isContractorType=
    // ${contractorType}&UserId=${userId}`;
                
    httpOptions['body'] = payload;
    return this.http.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LICENSE.SP_LISTING_LICENSE_FILTER}`,
      httpOptions
    ); 
    // return this.http.request(
    //   GLOBAL.HTTP_POST,
    //   url,
    //   httpOptions
    // ).pipe(map((response: any) => this.contractorAdapter.convertResponse<any>(response)));
  }

  public getCompanies(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_COMPANY_DATA}?Search=&StatusOfIndustry=&ISActive=&SDate=&EDate=&EntityType=&Legalstatus=
    &State=&City=&Country=`;
    return this.http.get<any[]>(url).pipe(map((response: any) => this.contractorAdapter.convertResponse<any>(response)));
  }

  public getPlants(companyCode: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_PLANTS}?Search=&Company=${companyCode}&State=&City=&Country=&SDate=&EDate=
    &UnitType=&Ownership=&LocatedAt=&ISESICApplicable=`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => this.contractorAdapter.convertResponse<any>(response)));
  }
  public getContractorsbydefault(userId: any, roleCode: any): Observable<any[]> {
    // tslint:disable-next-line: max-line-length
    const url = `${GLOBAL.APIS.LICENSE.GET_CONTRACTORSBY_DEFAULT}?UserId=${userId}&RoleCode=${roleCode}`;
    // console.log(url);
    return this.http.get<any[]>(url).pipe(
      map((response: any) => response.Data.Table));
  }
  public getState(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_STATE}?Search=`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => this.contractorAdapter.convertResponse<any>(response)));
  }

  public getDocumentTypes() {
    const url = `${GLOBAL.APIS.LICENSE.GET_DOCUMENT_TYPE}`;
    return this.http.get<any[]>(url).pipe(map((response: any) => response.Data));
  }
  public getDocumentTypesPageModuleWise(data) {
    return this.http.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_DOCUMENTS_TYPES_PAGE_MODULE_WISE}`, data);
  }

  public checkLicenseNoAvailability(licenseNo: string, id: any = ''): Observable<any> {
    const url = `${GLOBAL.APIS.LICENSE.Check_LicenseNo_Availability}?LicenseNo=${licenseNo}&LicenseCode=${id}`;
    return this.http.get<any[]>(url);
  }

  public addLicense(value: any) {
    const url = `${GLOBAL.APIS.LICENSE.ADD_LICENSE} `;
    return this.http.post(url, value).pipe(map((response: any) => response));
  }

  public updateLicense(value: any) {
    const url = `${GLOBAL.APIS.LICENSE.UPDATE_LICENSE}`;
    return this.http.post(url, value).pipe(map((response: any) => response));
  }

  public uploadDocuments(files: any[], params) {

    const url = `${GLOBAL.APIS.LICENSE.ADD_DOCUMENT}`;
    const userId = localStorage.getItem('UserID');
    const fd = new FormData();
    let docType = '';
    files.forEach((fileDoc, index) => {
      fd.append(`file${String(index)}`, fileDoc['file'], fileDoc['file']['name']);
      docType += files.length - 1 === index ? `${fileDoc.documentTypeCode}` : `${fileDoc.documentTypeCode},`;
    });
    fd.append('Image_Type', docType);
    fd.append('UserID', userId);
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const param = params[key];
        fd.append(key, String(param));
      }
    }

    const headers = new HttpHeaders({
      // 'Content-Type': 'multipart/form-data',
      // 'Accept': 'application/json'
    });
    // headers = headers.delete('Content-Type');
    const requestOptions = {
      headers,
      body: fd
    };

    return this.http.request(GLOBAL.HTTP_POST, url, requestOptions);
  }

  public getLicenseByLicenseCode(licenseCode: any = ''): Observable<any> {
    const url = `${GLOBAL.APIS.LICENSE.GET_LICENCE_BY_CODE}?LicenseCode=${licenseCode}`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => response.Data));
  }

  public getNatureOfWorks(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_NATUREOFWORKS}?Search=`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => this.contractorAdapter.convertResponse<any>(response)));
  }

  public getVerifyByList(): Observable<any> {
    const url = `${GLOBAL.APIS.USER_MANAGEMENT.GET_USER_LIST}?search=&Company=&Plant=&Designation=&State=&City=&Country=
    &Gender=&isLock=&isActive= &isDelete=&isSA=&Role=&TimeZone=&Language=&IsOrganizationUser=`;
    return this.http.get(url).pipe(
      map((response: any) => this.contractorAdapter.convertResponse<any>(response)));
  }

  // public getNatureOfLicense(shor): Observable<any[]> {
  //   const url = `${GLOBAL.APIS.MASTER_DATA.GET_MASTERITEMLIST}?Search=&ShortCode=${MasterItemEnum.NATURE_OF_LICENSE}`;
  //   return this.http.get<any[]>(url).pipe(
  //     map((response: any) => this.contractorAdapter.convertResponse<any>(response)));
  // }

  public getMasterItemListByCode(shortCode: string): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_MASTERITEMLIST}?Search=&ShortCode=${shortCode}`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => this.contractorAdapter.convertResponse<any>(response)));
  }

  public getContractors(excludeContractorCode: any = ''): Observable<any> {
    const url = `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_CONTRACTORS}?search=&sDate=&eDate=&selectedField=&isFilter=&isMain=
    &ExcludeContractor_Code=${excludeContractorCode}`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => this.contractorAdapter.convertResponse<any>(response)));
  }
  public getWorkOrderFilterDataForSubWorkorder(subContractorCode: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    const url = GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_WORKORDER_FILTER
      + '?sDate='
      + '&eDate='
      + '&search='
      + '&selectedField='
      + '&filterType='
      + '&condition='
      + '&isActive='
      + '&workOrderType='
      + '&workOrderOwner_Code='
      + '&workOrderHOD_Code='
      + '&department_Code='
      + '&isContractorType='
      + '&Plant_Code='
      + '&NatureOfWork_Code='
      + '&NatureOfWork_Code='
      + '&Contractor_Code='
      + '&SubContractor_Code=' + subContractorCode
      + '&WorkOrder_Code='
      + '&SubWorkOrder_Code='
      + '&WorkSiteArea_Code='
      + '&IsHRCApplicable='
      + '&Status_Code='
      + '&UserId=';
    return this.http.get<any[]>(url).pipe(
      map((response: any) => this.contractorAdapter.convertResponse<any>(response)));
  }


  public getWorkOrderFilterData(contractorCode: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    const url = GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_WORKORDER_FILTER
      + '?sDate='
      + '&eDate='
      + '&search='
      + '&selectedField='
      + '&filterType='
      + '&condition='
      + '&isActive='
      + '&workOrderType='
      + '&workOrderOwner_Code='
      + '&workOrderHOD_Code='
      + '&department_Code='
      + '&isContractorType='
      + '&Plant_Code='
      + '&NatureOfWork_Code='
      + '&NatureOfWork_Code='
      + '&Contractor_Code=' + contractorCode
      + '&SubContractor_Code='
      + '&WorkOrder_Code='
      + '&SubWorkOrder_Code='
      + '&WorkSiteArea_Code='
      + '&IsHRCApplicable='
      + '&Status_Code='
      + '&UserId=';
    return this.http.get<any[]>(url).pipe(
      map((response: any) => this.contractorAdapter.convertResponse<any>(response)));
  }

  // getPOGlobalSetting(code: string): Observable<any> {
  //   return this.http.get<any[]>(url).pipe(
  //     map((response: any) => this.contractorAdapter.convertResponse<any>(response)));
  // }

  private getNotUploadedItems(files: any[]) {
    return files.filter((item: any) => !item.isUploaded);
  }
}
