import { Component, OnInit } from '@angular/core';
import { AgNumberCountComponent, AgActionCellRendererComponent } from '../../core/components';
import { EcPoliciesService } from './ec-policies.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { GLOBAL } from 'src/app/app.globals';
@Component({
  selector: 'app-ec-policies',
  templateUrl: './ec-policies.component.html',
  styleUrls: ['./ec-policies.component.css']
})
export class EcPoliciesComponent implements OnInit {
 // tslint:disable-next-line: variable-name
  ForContractorLogin = true;
  contractorType: number;
  columnDefs: any[];
  rowData: [];
  constructor(private service: EcPoliciesService,
    private router: Router) {

    this.columnDefs = [
      {
        headerName: '#', field: '', width: 50, cellRendererFramework: AgNumberCountComponent,
        cellStyle: { textAlign: 'left' }, headerClass: 'f-align-left'
      },
      {
        headerName: 'EC Policy Code', field: 'WCPolicy_Code', width: 100,
        filterParams: { applyButton: true, clearButton: true }, filter: true, sortable: true,
        cellStyle: { textAlign: 'left' }, headerClass: 'f-align-left'
      },
      {
        headerName: 'Contractor Name', field: 'Company', width: 200,
        filterParams: { applyButton: true, clearButton: true }, filter: true, sortable: true, tooltipField: 'Company',
        cellStyle: { textAlign: 'left' }, headerClass: 'f-align-left',
        cellRenderer: function (params) { return '<span class="hover-shadow">' + params.value + '&nbsp;&nbsp; <i class="fa fa-clipboard" [cdkCopyToClipboard]=' + params.value + '></i> </span>' },
        tooltip: (params) => params.value,
      },
      {
        headerName: 'Plant Name', field: 'PlantName', width: 200,
        filterParams: { applyButton: true, clearButton: true }, filter: true, sortable: true,
        cellStyle: { textAlign: 'left' }, headerClass: 'f-align-left',
        cellRenderer: function (params) { return '<span class="hover-shadow">' + params.value + '&nbsp;&nbsp; <i class="fa fa-clipboard" [cdkCopyToClipboard]=' + params.value + '></i> </span>' },
        tooltip: (params) => params.value,
      },
      {
        headerName: 'ECPolicyNo', field: 'WCPolicyNo', width: 200,
        filterParams: { applyButton: true, clearButton: true }, filter: true,
        cellStyle: { textAlign: 'left' }, headerClass: 'f-align-left',
        cellRenderer: function (params) { return '<span class="hover-shadow">' + params.value + '&nbsp;&nbsp; <i class="fa fa-clipboard" [cdkCopyToClipboard]=' + params.value + '></i> </span>' },
        tooltip: (params) => params.value,
      },
      {
        headerName: 'WorkOrderNo', field: 'WorkOrderNo', width: 150,
        filterParams: { applyButton: true, clearButton: true }, filter: true, sortable: true,
        cellStyle: { textAlign: 'left' }, headerClass: 'f-align-left',
        cellRenderer: function (params) { return '<span class="hover-shadow">' + params.value + '&nbsp;&nbsp; <i class="fa fa-clipboard" [cdkCopyToClipboard]=' + params.value + '></i> </span>' },
        tooltip: (params) => params.value,
      },
      {
        headerName: 'StartDate', field: 'IssueDate', width: 100,
        filterParams: { applyButton: true, clearButton: true }, filter: true, sortable: true,
        cellStyle: { textAlign: 'left' }, headerClass: 'f-align-left',
        cellRenderer: (data) => {
          return moment(data.value).format('DD/MM/YYYY');
        },
        // cellRenderer: (data) => {
        //   if(data && data.value){
        //     return moment(data.value).format('DD/MM/YYYY');
        //   }
        // },
      },
      {
        headerName: 'EndDate', field: 'ValidtyDate', width: 100,
        filterParams: { applyButton: true, clearButton: true }, filter: true, sortable: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center',
        cellRenderer: (data) => {
          return moment(data.value).format('DD/MM/YYYY');
        },
        // cellRenderer: (data) => {
        //   if(data && data.value){
        //     return moment(data.value).format('DD/MM/YYYY');
        //   }
        // },
      },
      {
        headerName: 'Person', field: 'Person', width: 70,
        filterParams: { applyButton: true, clearButton: true }, filter: true, sortable: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center',
      },
      {
        headerName: 'Action', field: '', width: 50,
        sortable: false, suppressMenu: true, cellRendererFramework: AgActionCellRendererComponent
      }
    ];
    this.rowData = [];
    this.contractorType = 1;
  }

  ngOnInit() {

    const rolecode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
    if (rolecode === 119)
    // tslint:disable-next-line: brace-style
    {
      this.ForContractorLogin = true;
    }
    // tslint:disable-next-line: brace-style
    else
    // tslint:disable-next-line: brace-style
    {
      this.ForContractorLogin = true;
    }
    this.getEcPolicies(this.contractorType);
  }
  /**
   * @description Get all the ecPolicies
   * @author Sonal Prajapati
   */
  getEcPolicies(contractorType: number) {

    // this.service.getAllEcPolicies(contractorType).subscribe((res: []) => {
    //   if (res.length > 0) {
    //     this.rowData = res;
    //   }
    // });
    this.service.getWCPolicy(contractorType).subscribe((res: []) => {
      if (res.length > 0) {
        this.rowData = res;
      }
    });
  }
  onTabChange(event) {
    this.getEcPolicies(event.nextId);
  }

  editECPolicy(rowData) {

    this.router.navigate([`/contractor/edit-ecpolicy/${rowData.WCPolicy_Code}`]);
  }
}
