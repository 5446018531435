import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, } from '@angular/forms';
import { LogHelperService } from 'src/app/core/services/log-helper.service';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.css']
})
export class CommentComponent implements OnInit {
  @Output() public handleClose: EventEmitter<any> = new EventEmitter<any>();
  HrComment: any = '';
  public formGroup: FormGroup;
  constructor(private logHelperService: LogHelperService,) { }

  ngOnInit() {

  }

  public onClose(data?: any): void {
    this.handleClose.emit(data);
  }
  next() {
    if (JSON.parse(localStorage.getItem('Role'))[0].Role_Code === 118) {
      if (!this.HrComment) {
        this.logHelperService.logError({ message: 'Please enter the comment!' });
        return false;
      }
    }
    this.onClose(this.HrComment);
  }

}
