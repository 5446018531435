import { DateDetailsComponent } from './date-details/date-details.component';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbTabChangeEvent, NgbModal, NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { GLOBAL, ROLECODE } from 'src/app/app.globals';
import { SharedService } from 'src/app/core/services';
import * as moment from 'moment';
import { VerifyComponent } from './verify/verify.component';
import { GatePassRequestsService } from '../gate-pass-requests.service';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { SharedDataService } from 'src/app/core/services/shared-data.service';
import { OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { CookieService } from 'ngx-cookie-service';
import { DISABLED } from '@angular/forms/src/model';
import * as _ from 'lodash';
import { CommentComponent } from './comment/comment.component';

const browserWindow = window || {};
const browserWindowEnv = browserWindow['__env'] || {};

const dateFormat = 'DD-MM-YYYY';
export const MY_MOMENT_FORMATS = {
  parseInput: dateFormat,
  fullPickerInput: dateFormat,
  datePickerInput: dateFormat,
  timePickerInput: dateFormat,
  monthYearLabel: dateFormat,
  dateA11yLabel: dateFormat,
  monthYearA11yLabel: dateFormat,
};
let newdate: Date;
let newdate1: Date;
const today = new Date();
const today1 = (new Date(today)).toLocaleDateString();

@Component({
  selector: 'app-approval-flow',
  templateUrl: './approval-flow.component.html',
  styleUrls: ['./approval-flow.component.css'],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
  { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-IN' }]
})
export class ApprovalFlowComponent implements OnInit {

  public userAllowedForNewRequestForHRTab = [118, 121, 122];
  public allowedUserForNewRequestForHRTab = [];

  @ViewChild(NgbTabset) public tabSet: NgbTabset;
  public hideVerificationSection: boolean;
  @Input() resolveObject;
  @Input() requestType;
  @Input() Request_Code;
  approvalData: any = {};
  labourDoc = [];
  show = true;
  hrApproved = false;
  public activedate = false;
  public gpvaliditydate = false;
  public disableenrollment = false;
  public disablegpissue = false;
  disablegpreturned = false;
  disablegpdeactivated = false;
  tabstatus = 'N';
  verifybutton = false;
  // updatebutton = true;
  roleCode = JSON.parse(localStorage.getItem('Role'));
  action = '';
  approve = '';
  enableAction = false;
  enableActionSaveBtn = false;
  documents = [];
  sections = [];
  cardType: any[];
  masterData = [];
  regularweekly: { Id: number, Name: string; }[];
  shiftGroup = [];
  siteshiftGroup = [];
  ohc = false;
  ehs = false;
  security = false;
  public hrTabAllowedUser = false;
  hrverify = false;
  section;
  labourStatus = "Active"
  nextDueDateCount: number
  public isVerifyPf;
  private globalSettings: any;
  public isOHCRoleCode: boolean = false;
  globalSettingList: any = [];
  IsPrviousApproved: any = 0;
  documentUrl = GLOBAL.BASE_URL_DOC;
  constructor(
    private modalService: NgbModal,
    private gatePassRequestsService: GatePassRequestsService,
    public sharedService: SharedService,
    public activeModal: NgbActiveModal,
    private logHelperService: LogHelperService,
    public sharedDataService: SharedDataService,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    const userRoles = JSON.parse(localStorage.getItem('Role'));
    const promise1 = new Promise((resolve, reject) => {
      this.gatePassRequestsService.getRolePermission({
        "Request_Code": this.Request_Code, "RequireType": 0, "ActionType": 0, "UserId": localStorage.getItem('UserID')
      }).subscribe((res: any) => {
        if (res.length) {
          this.IsPrviousApproved = res[0].IsPrviousApproved;
        }
        resolve(res)
      });
    })
    const promise2 = new Promise((resolve, reject) => {
      this.gatePassRequestsService.globalSettingCode('').subscribe((res: any) => {
        this.globalSettingList = res.Data.Table;
        let roleCode = res.Data.Table.find(item => item.Code === 'MOD52PG195REQVERYN')
        this.nextDueDateCount = res.Data.Table.find(item => item.Code === 'MOD152PG195GPMEDVDT_1').Value
        console.log(res.Data.Table)
        this.allowedUserForNewRequestForHRTab = res.Data.Table.find(item => item.Code === 'MOD52PG195REQVERROLL').Role;
        let isUserRoleCodeFound = false;
        userRoles.forEach(role => {
          if (this.allowedUserForNewRequestForHRTab.includes(role.Role_Code)) {
            isUserRoleCodeFound = true;
          }
        })
        if (isUserRoleCodeFound) {
          if (roleCode.Value === "1") {
            this.isVerifyPf = true;
          } else if (roleCode.Value === "0") {
            this.isVerifyPf = false;
          }
        } else {
          this.isVerifyPf = false;
        }

        this.setActiveTabByRole();
        resolve(res)
      })
    })
    Promise.all([promise1, promise2]).then((res) => {
      this.hideFooterForContractor();
      this.tabvalidation();
  
      if (this.resolveObject.RequestType_Code === 105) {
        this.disablegpreturned = true;
        this.disablegpdeactivated = true;
      } else {
        this.disablegpreturned = false;
        this.disablegpdeactivated = false;
      }
  
      this.resolveObject.labourData.ReqApprovalInfo.forEach((row) => {
        if (row.Role_Code === 111) {
          // this.disableenrollment = true;
          this.disablegpissue = true;
        } else {
          // this.disableenrollment = false;
          this.disablegpissue = false;
        }
      });
      if (this.resolveObject.labourData.IsSuspended === 1) {
        this.labourStatus = "Debarrded";
      } else if (this.resolveObject.labourData.IsSuspended === 2) {
        if (this.resolveObject.labourData.SuspendEndDate) {
          const endDate = moment(this.resolveObject.labourData.SuspendEndDate);
          const diff = endDate.diff(
            moment(moment().format("YYYY-MM-DD")),
            "days"
          );
          if (
            diff > 0 ||
            endDate.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
          ) {
            this.labourStatus = "Suspended";
          } else {
            this.labourStatus = "Active";
          }
        } else {
          this.labourStatus = "Suspended";
        }
      } else {
        this.labourStatus = "Active";
      }
      console.log(this.resolveObject);
      this.approvalData = this.resolveObject.approvalData;
      this.disableActionSaveBtn(this.approvalData);
  
      // this.approvalData.gatepassValidityDate = moment(this.approvalData.MaxValiditydate, 'M/DD/YYYY').toDate();
      const d = this.globalSettingList.find(item => item.Code === 'MOD152PG195GPVDT');
      this.approvalData.MaxValiditydate =moment(this.approvalData.MaxValiditydate).add(d && d.Value?Number(d.Value):0, 'days').format('YYYY-MM-DD');
      this.approvalData.gatepassValidityDate = this.approvalData.gatepassValidityDate ? moment(this.approvalData.gatepassValidityDate, 'dd/MM/yyyy').toDate() : null;
      this.approvalData.CheckUpDate = this.approvalData.CheckUpDate ? moment(this.approvalData.CheckUpDate, 'dd/MM/yyyy').toDate() : null;
      this.approvalData.NextScheduledate = this.approvalData.NextScheduledate ? moment(this.approvalData.NextScheduledate, 'dd/MM/yyyy').toDate() : null;
      this.approvalData.OHCNextDuedate = this.approvalData.OHCNextDuedate ? moment(this.approvalData.OHCNextDuedate, 'dd/MM/yyyy').toDate() : null;
      this.approvalData.XrayValidityDate = this.approvalData.XrayValidityDate ? moment(this.approvalData.XrayValidityDate, 'dd/MM/yyyy').toDate() : null;
      this.approvalData.EyeTestingValidityDate = this.approvalData.EyeTestingValidityDate ? moment(this.approvalData.EyeTestingValidityDate, 'dd/MM/yyyy').toDate() : null;
      this.approvalData.ReExamingDate = this.approvalData.ReExamingDate ? moment(this.approvalData.ReExamingDate, 'dd/MM/yyyy').toDate() : null;
      const dates = [];
      this.approvalData.ECPolicyValidityDat ? dates.push(new Date(this.approvalData.ECPolicyValidityDate)) : null;
      this.approvalData.LicenseValidityDate ? dates.push(new Date(this.approvalData.LicenseValidityDate)) : null;
      this.approvalData.POValidityDate ? dates.push(new Date(this.approvalData.POValidityDate)) : null;
      this.approvalData.MaxValiditydate ? dates.push(new Date(this.approvalData.MaxValiditydate)) : null;
      this.approvalData.MedicalValidityDate ? dates.push(new Date(this.approvalData.MedicalValidityDate)) : null;
      this.approvalData.InductionTrainingDate ? dates.push(new Date(this.approvalData.InductionTrainingDate)) : null;
      this.approvalData.XrayValidityDate ? dates.push(new Date(this.approvalData.XrayValidityDate)) : null;
      this.approvalData.EyeTestingValidityDate ? dates.push(new Date(this.approvalData.EyeTestingValidityDate)) : null;
  
      this.approvalData.minValidityDate = _.min(dates);
      this.approvalData.gatepassValidityDate = this.approvalData.minValidityDate;
      console.log(this.approvalData['SiteShiftGroup_Code'], this.approvalData.AadharCard, this.approvalData);
      // this.approvalData.SiteShiftGroup_Code = this.approvalData.SiteShiftGroup_Code ? this.approvalData.SiteShiftGroup_Code : null;
      this.approvalData.HrComment = this.approvalData.HrComment ? this.approvalData.HrComment : null;
  
      this.labourDoc = this.resolveObject.labourDoc;
      this.hrApproved = this.resolveObject.hrApproved;
      this.getAllSections();
      this.getMasterdata();
      // this.shiftGroupData();
      this.siteshiftGroupData();
      if (this.approvalData.Photo) {
        this.approvalData.Photo = GLOBAL.PHOTO_URL + this.approvalData.Photo;
      }
  
      this.isOHCRoleCode = userRoles.findIndex(role => role.Role_Code == 109) >= 0 ? true : false;
      const isRoleExist = userRoles.some(x => this.allowedUserForNewRequestForHRTab.includes(x.Role_Code));
    console.log('isRoleExist',userRoles,isRoleExist,this.resolveObject);

      if (isRoleExist && this.approvalData.RequestTypeName == 'New Request') {
        this.enableAction = false;
        // this.verifybutton = true;
        if (this.resolveObject.labourData && this.resolveObject.labourData.ULC) {
          this.verifybutton = false;
        } else {
          this.verifybutton = true;
        }
      } else {
        this.enableAction = true;
        this.verifybutton = false;
      }
  
      // if (JSON.parse(localStorage.getItem('Role'))[0].Role_Code === 119) {
      //   this.updatebutton = true;
      // } else {
      //   this.updatebutton = false;
      // }
  
      if (this.approvalData.IsVerifyEPF === true) {
        this.hrverify = false;
        this.approvalData.verificationDate = (new Date(this.approvalData.verificationDate)).toLocaleDateString();
        this.approvalData.ESICVerificationDate = (new Date(this.approvalData.ESICVerificationDate)).toLocaleDateString();
        this.approvalData.PFExtendedDate = (new Date(this.approvalData.PFExtendedDate)).toLocaleDateString();
        this.approvalData.ESICExtendedDate = (new Date(this.approvalData.ESICExtendedDate)).toLocaleDateString();
  
  
        this.approvalData.verificationDate = this.approvalData.verificationDate;
        this.approvalData.ESICVerificationDate = this.approvalData.ESICVerificationDate;
        this.approvalData.PFExtendedDate = this.approvalData.PFExtendedDate;
        this.approvalData.ESICExtendedDate = this.approvalData.ESICExtendedDate;
  
  
        this.approvalData.UserforEPF = this.approvalData.UserName;
        this.approvalData.UserforESIC = this.approvalData.UserName;
      } else if (this.approvalData.IsVerifyESIC === true) {
        this.hrverify = false;
        this.approvalData.verificationDate = (new Date(this.approvalData.verificationDate)).toLocaleDateString();
        this.approvalData.ESICVerificationDate = (new Date(this.approvalData.ESICVerificationDate)).toLocaleDateString();
        this.approvalData.PFExtendedDate = (new Date(this.approvalData.PFExtendedDate)).toLocaleDateString();
        this.approvalData.ESICExtendedDate = (new Date(this.approvalData.ESICExtendedDate)).toLocaleDateString();
  
  
        this.approvalData.verificationDate = this.approvalData.verificationDate;
        this.approvalData.ESICVerificationDate = this.approvalData.ESICVerificationDate;
        this.approvalData.PFExtendedDate = this.approvalData.PFExtendedDate;
        this.approvalData.ESICExtendedDate = this.approvalData.ESICExtendedDate;
  
  
        this.approvalData.UserforEPF = this.approvalData.UserName;
        this.approvalData.UserforESIC = this.approvalData.UserName;
      }
    })
    
    // this.sharedService.globalSettingsDataSource$.subscribe((settings: any) => {
    //   if (settings && settings.Data && settings.Data.Table) {
    //     this.globalSettings = settings.Data.Table;
    //   }
    // });
    // console.log(this.globalSettings,'GLOBALE')
    // this.isVerifyPf = this.globalSettings.find(setting => setting.Code === browserWindowEnv.PF_VERIFICATION_CHECK);
    // console.log(this.isVerifyPf)

    if (this.resolveObject.RequestType_Code === 105) {
      this.disablegpreturned = true;
      this.disablegpdeactivated = true;
    } else {
      this.disablegpreturned = false;
      this.disablegpdeactivated = false;
    }

    this.resolveObject.labourData.ReqApprovalInfo.forEach((row) => {
      if (row.Role_Code === 111) {
        // this.disableenrollment = true;
        this.disablegpissue = true;
      } else {
        // this.disableenrollment = false;
        this.disablegpissue = false;
      }
    });
    if (this.resolveObject.labourData.IsSuspended === 1) {
      this.labourStatus = "Debarrded";
    } else if (this.resolveObject.labourData.IsSuspended === 2) {
      if (this.resolveObject.labourData.SuspendEndDate) {
        const endDate = moment(this.resolveObject.labourData.SuspendEndDate);
        const diff = endDate.diff(
          moment(moment().format("YYYY-MM-DD")),
          "days"
        );
        if (
          diff > 0 ||
          endDate.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
        ) {
          this.labourStatus = "Suspended";
        } else {
          this.labourStatus = "Active";
        }
      } else {
        this.labourStatus = "Suspended";
      }
    } else {
      this.labourStatus = "Active";
    }
    console.log(this.resolveObject);
    this.approvalData = this.resolveObject.approvalData;
    this.disableActionSaveBtn(this.approvalData);

    // this.approvalData.gatepassValidityDate = moment(this.approvalData.MaxValiditydate, 'M/DD/YYYY').toDate();
    const d = this.globalSettingList.find(item => item.Code === 'MOD152PG195GPVDT');
    this.approvalData.MaxValiditydate =moment(this.approvalData.MaxValiditydate).add(d && d.Value?Number(d.Value):0, 'days').format('YYYY-MM-DD');
    this.approvalData.gatepassValidityDate = this.approvalData.gatepassValidityDate ? moment(this.approvalData.gatepassValidityDate, 'dd/MM/yyyy').toDate() : null;
    this.approvalData.CheckUpDate = this.approvalData.CheckUpDate ? moment(this.approvalData.CheckUpDate, 'dd/MM/yyyy').toDate() : null;
    this.approvalData.NextScheduledate = this.approvalData.NextScheduledate ? moment(this.approvalData.NextScheduledate, 'dd/MM/yyyy').toDate() : null;
    this.approvalData.OHCNextDuedate = this.approvalData.OHCNextDuedate ? moment(this.approvalData.OHCNextDuedate, 'dd/MM/yyyy').toDate() : null;
    this.approvalData.XrayValidityDate = this.approvalData.XrayValidityDate ? moment(this.approvalData.XrayValidityDate, 'dd/MM/yyyy').toDate() : null;
    this.approvalData.EyeTestingValidityDate = this.approvalData.EyeTestingValidityDate ? moment(this.approvalData.EyeTestingValidityDate, 'dd/MM/yyyy').toDate() : null;
    this.approvalData.ReExamingDate = this.approvalData.ReExamingDate ? moment(this.approvalData.ReExamingDate, 'dd/MM/yyyy').toDate() : null;
    const dates = [];
    this.approvalData.ECPolicyValidityDat ? dates.push(new Date(this.approvalData.ECPolicyValidityDate)) : null;
    this.approvalData.LicenseValidityDate ? dates.push(new Date(this.approvalData.LicenseValidityDate)) : null;
    this.approvalData.POValidityDate ? dates.push(new Date(this.approvalData.POValidityDate)) : null;
    this.approvalData.MaxValiditydate ? dates.push(new Date(this.approvalData.MaxValiditydate)) : null;
    this.approvalData.MedicalValidityDate ? dates.push(new Date(this.approvalData.MedicalValidityDate)) : null;
    this.approvalData.InductionTrainingDate ? dates.push(new Date(this.approvalData.InductionTrainingDate)) : null;
    this.approvalData.XrayValidityDate ? dates.push(new Date(this.approvalData.XrayValidityDate)) : null;
    this.approvalData.EyeTestingValidityDate ? dates.push(new Date(this.approvalData.EyeTestingValidityDate)) : null;

    this.approvalData.minValidityDate = _.min(dates);
    this.approvalData.gatepassValidityDate = this.approvalData.minValidityDate;
    console.log(this.approvalData['SiteShiftGroup_Code'],this.approvalData.AadharCard , this.approvalData);
    // this.approvalData.SiteShiftGroup_Code = this.approvalData.SiteShiftGroup_Code ? this.approvalData.SiteShiftGroup_Code : null;
    this.approvalData.HrComment = this.approvalData.HrComment ? this.approvalData.HrComment : null;

    this.labourDoc = this.resolveObject.labourDoc;
    this.hrApproved = this.resolveObject.hrApproved;
    this.getAllSections();
    this.getMasterdata();
    // this.shiftGroupData();
    this.siteshiftGroupData();
    if (this.approvalData.Photo) {
      this.approvalData.Photo = GLOBAL.PHOTO_URL + this.approvalData.Photo;
    }
    const isRoleExist = userRoles.some(x => this.userAllowedForNewRequestForHRTab.includes(x.Role_Code));
    if (isRoleExist && this.approvalData.RequestTypeName == 'New Request') {
      this.enableAction = false;
      // this.verifybutton = true;
      if (this.resolveObject.labourData && this.resolveObject.labourData.ULC) {
        this.verifybutton = false;
      } else {
      this.verifybutton = true;
      }
    } else {
      this.enableAction = true;
      this.verifybutton = false;
    }

    // if (JSON.parse(localStorage.getItem('Role'))[0].Role_Code === 119) {
    //   this.updatebutton = true;
    // } else {
    //   this.updatebutton = false;
    // }

    if (this.approvalData.IsVerifyEPF === true) {
      this.hrverify = false;
      this.approvalData.verificationDate = (new Date(this.approvalData.verificationDate)).toLocaleDateString();
      this.approvalData.ESICVerificationDate = (new Date(this.approvalData.ESICVerificationDate)).toLocaleDateString();
      this.approvalData.PFExtendedDate = (new Date(this.approvalData.PFExtendedDate)).toLocaleDateString();
      this.approvalData.ESICExtendedDate = (new Date(this.approvalData.ESICExtendedDate)).toLocaleDateString();


      this.approvalData.verificationDate = this.approvalData.verificationDate;
      this.approvalData.ESICVerificationDate = this.approvalData.ESICVerificationDate;
      this.approvalData.PFExtendedDate = this.approvalData.PFExtendedDate;
      this.approvalData.ESICExtendedDate = this.approvalData.ESICExtendedDate;


      this.approvalData.UserforEPF = this.approvalData.UserName;
      this.approvalData.UserforESIC = this.approvalData.UserName;
    } else if (this.approvalData.IsVerifyESIC === true) {
      this.hrverify = false;
      this.approvalData.verificationDate = (new Date(this.approvalData.verificationDate)).toLocaleDateString();
      this.approvalData.ESICVerificationDate = (new Date(this.approvalData.ESICVerificationDate)).toLocaleDateString();
      this.approvalData.PFExtendedDate = (new Date(this.approvalData.PFExtendedDate)).toLocaleDateString();
      this.approvalData.ESICExtendedDate = (new Date(this.approvalData.ESICExtendedDate)).toLocaleDateString();


      this.approvalData.verificationDate = this.approvalData.verificationDate;
      this.approvalData.ESICVerificationDate = this.approvalData.ESICVerificationDate;
      this.approvalData.PFExtendedDate = this.approvalData.PFExtendedDate;
      this.approvalData.ESICExtendedDate = this.approvalData.ESICExtendedDate;


      this.approvalData.UserforEPF = this.approvalData.UserName;
      this.approvalData.UserforESIC = this.approvalData.UserName;
    }
    // const http = new XMLHttpRequest();
    // http.open('HEAD', url, false);
    // http.send();
    // if (http.status !== 404) {
    //   this.approvalData.Photo = url;
    // } else {
    //   this.approvalData.Photo = || 'assets/Image/blank-face.jpg''assets/Image/blank-face.jpg';
    // }

    // if (this.approve == 'ActionSave') {
    //   this.actionSave();
    // } else if (this.approve == 'UpdateAction') {
    //   this.actionUpdate();
    // }
  }

  private disableActionSaveBtn(data: any) {
    const roles = JSON.parse(localStorage.getItem('Role'));
    // const requiredRoles = [118, 121, 122];
    const isAllowed = roles.some(item => this.allowedUserForNewRequestForHRTab.includes(item.Role_Code));
    if (!data.ULCCode && isAllowed) {
      this.enableActionSaveBtn = true;
    }
  }

  stepclose(resData) {
    const data = {
      ULC: resData ? resData.ULC : this.approvalData.ULCCode,
      ShiftGroup: this.approvalData.SiteShiftGroup_Code,
      GatePassNo: resData ? resData.GatePassNo : this.approvalData.GatePassNo,
      isCancel: !resData
    };
    this.activeModal.close(data);
  }

  getBg1Class(IsSuspended: any, status: any) {
    if (status === "Active") {
      return "active-color";
    }
    if (IsSuspended == 2) {
      return "cancel-color";
    } else if (IsSuspended == 1) {
      return "debard-color";
    } else {
      return "active-color";
    }
  }

  getBgClass(IsSuspended: any, status: any) {
    if (status === "Active") {
      return "profile_img_green";
    }
    if (IsSuspended == 2) {
      return "profile_img_red";
    } else if (IsSuspended == 1) {
      return "profile_img_debard";
    } else {
      return "profile_img_green";
    }
  }

  getIconClass(IsSuspended: any, status: any) {
    if (status === "Active") {
      return "fa fa-check";
    }
    if (IsSuspended == 2) {
      return "icon-saDC-debarred1";
    } else if (IsSuspended == 1) {
      return "icon-saDC-debarred";
    } else {
      return "icon-saDC-debarred";
    }

  }

  getBg2Class(IsSuspended: any, status: any) {
    if (status === "Active") {
      return "status_text_green";
    }
    if (IsSuspended == 2) {
      return "status_text_red";
    } else if (IsSuspended == 1) {
      return "status_text_debard";
    } else {
      return "status_text_green";
    }
  }

  public tabChange(event: NgbTabChangeEvent) {
    if (event.nextId === 'tab-documents' && this.documents.length === 0) {
      this.getDocuments();
    }
  }

  openVerify() {
    const modalRef = this.modalService.open(VerifyComponent, { size: 'lg', backdrop: 'static', windowClass: 'verification-window' });
    modalRef.componentInstance.resolveObject = this.resolveObject;
    modalRef.result.then((result) => {
      if (result) {
        this.action = 'Approved';
        this.enableAction = true;
        this.approvalData.ULCCode = result.ULC;
        this.resolveObject.labourData.ULC = result.ULC;
        this.enableActionSaveBtn = false;
      }
    });
  }

  chkFitnessStatus(value: string) {
 
  }
  chkFitnessStatus1(value: any) {
    console.log('value', value)
    if (value === 1) {
      this.action = 'Approved';
    } else if (value === 2) {
      this.action = 'Rejected';
    } else if (value === 3) {
      this.action = 'Hold';
    } else {
      this.action = '';
    }
  }

  checkFNSTraningStatus(value: string){
 
  }

  checkvalidation() {
    if (this.action == null || this.action === '') {
      this.logHelperService.logError({ message: 'Please select any action!' });
      return false;
    }
    if (JSON.parse(localStorage.getItem('Role'))[0].Role_Code === 109) {
      if (this.approvalData.FitnessStatus == null || this.approvalData.FitnessStatus == ''
        || this.approvalData.CheckUpDate == null || this.approvalData.CheckUpDate == '') {
        this.logHelperService.logError({ message: 'Plaese fill detail' });
        return false;
      }
    }
    if (JSON.parse(localStorage.getItem('Role'))[0].Role_Code === 110) {
      if (this.approvalData.FNSTraningStatus == null || this.approvalData.FNSTraningStatus === '') {
        this.logHelperService.logError({ message: 'Please select a status!' });
        return false;
      }
    }
    if (JSON.parse(localStorage.getItem('Role'))[0].Role_Code === 118) {
      if (!this.approvalData.SiteShiftGroup_Code) {
        this.logHelperService.logError({ message: 'Please select the Site Shift Group!' });
        return false;
      }
    }
    // if (JSON.parse(localStorage.getItem('Role'))[0].Role_Code === 118) {
    //   if (!this.approvalData.HrComment) {
    //     this.logHelperService.logError({ message: 'Please enter the comment!' });
    //     return false;
    //   }
    // }

    if (this.isVerifyPf) {
      if (!this.approvalData.IsVerifyEPF) {
        this.logHelperService.logError({ message: 'Please verify the EPF details!' });
        return false;
      }
    }
    if (this.isVerifyPf) {
      if (!this.approvalData.IsVerifyESIC) {
        this.logHelperService.logError({ message: 'Please verify the ESIC details!' });
        return false;
      }
    }
    return true;
  }

  prepareReqForActionSave(action: string) {

    const labourCodes = []; // "";
    labourCodes.push(this.resolveObject.labourData.Labour_Code);
    const objgatepass = {
      UserEmail: GLOBAL.USER.EMAIL_ID,
      RoleCode: JSON.parse(localStorage.getItem('Role'))[0].Role_Code,
      UserName: GLOBAL.USER.EMAIL_ID,
      requestTypeCode: this.resolveObject.RequestType_Code,
      Action: action,
      labourcodes: labourCodes.join(','),
      UserId: localStorage.getItem('UserID'),
      Comment: this.approvalData.HrComment,
      ULC: this.approvalData.ULCCode,
      verificationDate: this.approvalData.verificationDate,
      ESICVerificationDate: this.approvalData.ESICVerificationDate,
      IsVerifyEPF: this.approvalData.IsVerifyEPF,
      IsVerifyESIC: this.approvalData.IsVerifyESIC,
      // ULC : ULC,
      // GatePassNo : GatePassNo,
      EntityComplianceInfoView: {
        ContractorVendor_Code: this.approvalData.ContractorVendor_Code,
        State_Code: this.approvalData.State_Code,
        RegistrationNumber: this.approvalData.RegistrationNumber
      },
      // EIC
      GatePassValidityDate: moment(this.approvalData.gatepassValidityDate).format(this.sharedService.datePickerSettings.requestFormat),
      Section: this.approvalData.SectionName,
      ShiftGroupView: {
        // ShiftGroupCode: this.approvalData.ShiftGroup_Code,
        SiteShiftGroupCode: [this.approvalData.SiteShiftGroup_Code],
        ULC: this.approvalData.ULC,
        Gatepassno: this.approvalData.Gatepassno,
        PlantCode: this.approvalData.PlantCode
      },
      // OHC
      FitnessStatus: this.approvalData.FitnessStatus,
      Checkupdate: moment(this.approvalData.CheckUpDate).format(this.sharedService.datePickerSettings.requestFormat),
      NextDuedate: moment(this.approvalData.OHCNextDuedate).format(this.sharedService.datePickerSettings.requestFormat),
      NextScheduledate: moment(this.approvalData.NextScheduledate).format(this.sharedService.datePickerSettings.requestFormat),
      From32: this.approvalData.From32,
      From35: this.approvalData.From35,
      XrayExaminationrequired: this.approvalData.examinationrequired,
      TreatmentDetails: this.approvalData.TreatmentDetails,
      XrayValidityDate: moment(this.approvalData.XrayValidityDate).format(this.sharedService.datePickerSettings.requestFormat),
      ExaminationDetails: this.approvalData.ExaminationDetails,
      EyeTestingValidityDate: moment(this.approvalData.EyeTestingValidityDate).format(this.sharedService.datePickerSettings.requestFormat),
      NonHazardousDetails: this.approvalData.NonHazardousDetails,
      ReExamingDate: moment(this.approvalData.ReExamingDate).format(this.sharedService.datePickerSettings.requestFormat),
      // FNS
      InductionTraining: this.approvalData.InductionTraining,
      FNSTraningStatus: this.approvalData.FNSTraningStatus,
      FNSNextDuedate: moment(this.approvalData.FNSNextDuedate).format(this.sharedService.datePickerSettings.requestFormat),
      FNSNextScheduledate: moment(this.approvalData.FNSNextScheduledate).format(this.sharedService.datePickerSettings.requestFormat),
      FNSIsFireFighter: this.approvalData.FNSIsFireFighter,
      // Security
      Enrollment: this.approvalData.Enrollment,
      Gatepassissue: this.approvalData.Gatepassissue,
      Gatepassreturned: this.approvalData.Gatepassreturned,
      Gatepassdeactivated: this.approvalData.Gatepassdeactivated,
      GatePassValidityDateHR: moment(this.approvalData.gatepassValidityDate).format(this.sharedService.datePickerSettings.requestFormat),
      IssueDate: this.approvalData.IssueDate,
      HrComment: this.approvalData.HrComment
    };
    return objgatepass;
  }


  getDateDetail() {
    const reqeustObj = {
      ULC: null,
      GatePassNo: null,
      Request_Code: this.Request_Code,
      RequestType_Code: null,
      Plant_Code: null,
      Contractor_Code: null,
      WorkOrder_Code: null,
      UserID: null,
      isCheck: 1
    };

    this.gatePassRequestsService.getMaxValidityDate(reqeustObj).subscribe((response) => {
      // let maxValidityDate = moment().add(6, 'months').format('YYYY-MM-DD');
      let maxValidityDate = this.approvalData.MaxValiditydate;
      if (response && response.date) {
        const d = this.globalSettingList.find(item => item.Code === 'MOD152PG195GPVDT');
        maxValidityDate = moment(response.date).add(d ? d : 0, 'days').format('YYYY-MM-DD');
        // maxValidityDate = moment(response.date).add(6, 'months').format('YYYY-MM-DD');
      }
      const modalRef = this.modalService.open(DateDetailsComponent, { ariaLabelledBy: 'modal-basic-title' });
      modalRef.componentInstance.approvalData = this.approvalData;
      modalRef.componentInstance.requestType = this.resolveObject.RequestType_Code;
      modalRef.componentInstance.maxValidityDate = new Date(maxValidityDate);
      modalRef.result.then((result) => {
        if (result) {
          console.log(result);
        }
      });
    })

  }

  // getDateDetail() {
  //   this.modalService.open(DateDetailsComponent, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
  //     this.closeResult = `Closed with: ${result}`;
  //   }, (reason) => {
  //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //   });
  // }

  commentModel(isUpdateCall: boolean = false) {
    const modalRef = this.modalService.open(CommentComponent, {
      backdrop: 'static'
    });
    modalRef.componentInstance.handleClose.subscribe((res: any) => {
      if (res) {
        this.approvalData.HrComment = res;
        this.actionSaveData(isUpdateCall);
      }
      modalRef.close('')
    });
  }
  actionSave(isUpdateCall: boolean = false) {
    let isValid = false;
    if (isUpdateCall) {
      isValid = true;
    } else {
      isValid = this.checkvalidation();
    }
    if (isValid) {
      this.commentModel(isUpdateCall);
      // $scope.loading = true;
    }
  }
  actionSaveData(isUpdateCall: boolean = false) {
    const objgatepass: any = this.prepareReqForActionSave(this.action);
    objgatepass.isUpdateCall = isUpdateCall;
    this.gatePassRequestsService.actionSaveMulti(objgatepass).subscribe((msgdata: any) => {
      if (msgdata.Data) {
        // $scope.gridOptions.api.setDatasource($scope.labourDataSource);
        if (objgatepass.requestTypeCode === 105) {
          this.logHelperService.logSuccess({ message: `${this.action} Gate Pass cancel!` });
          this.stepclose(msgdata.Data);
        } else {
          this.logHelperService.logSuccess({ message: `Data ${this.action} Successfully!` });
          this.stepclose(msgdata.Data);
        }
        // objgatepass.Labourcodes = angular.copy(msgdata.data.LabourCodes);
        this.gatePassRequestsService.sendMailForApproval(objgatepass);
        // $scope.listLabourCodes = [];
        // $scope.listRequestCodes = [];
        // $scope.gridOptions.api.setDatasource($scope.labourDataSource);
      } else {
        this.logHelperService.logError({ message: `Can't ${this.action} Data!` });
        this.stepclose(msgdata.Data);
      }
      // }, function () {
      //   alert('Error in adding record');
      // });
    }, (error) => {
      this.logHelperService.logError({ message: `Error in adding record!` });

    });
  }

  actionUpdate() {

  }

  getDocuments() {

    this.gatePassRequestsService.getDocumentForLabourVerify(this.resolveObject.labourData.Labour_Code)
      .subscribe((verificationDoc: any) => {
        console.log("docs", verificationDoc)
        if (verificationDoc.Status) {
          this.documents = verificationDoc.Data;
        }
      });
  }

  getAllSections() {
    this.gatePassRequestsService.getAllSections().subscribe((response: any) => {
      if (response.Status) {
        this.sections = response;
        if (this.approvalData.SectionName == null || this.approvalData.SectionName === '') {
        } else {
          const sectionMasterdata = [];
          this.sections.forEach((val) => {
            if (val.Name === this.approvalData.SectionName) {
              sectionMasterdata.push(val);
            }
          });
          if (sectionMasterdata.length === 1) {
            this.section = sectionMasterdata[0].Section_Code;
          }
        }
      }

    });
  }

  getMasterdata() {
    this.sharedDataService.getMasterData().subscribe((masterList: any) => {
      console.log('master data', masterList);
      this.masterData = masterList.Data.Table;
      this.cardType = this.masterData.filter(list => list.Parent_Code === 601);
      this.regularweekly = [
        { Id: 1, Name: 'Sun' },
        { Id: 2, Name: 'Mon' }, { Id: 3, Name: 'Tue' },
        { Id: 4, Name: 'Wed' }, { Id: 5, Name: 'Thu' },
        { Id: 6, Name: 'Fri' }, { Id: 7, Name: 'Sat' }];
    });
  }

  shiftGroupData() {
    return this.gatePassRequestsService.getShiftGroupData().subscribe((res: any) => {
      this.shiftGroup = res.Data.Table;
    });
  }

  siteshiftGroupData() {
    return this.gatePassRequestsService.getSiteShiftGroupData().subscribe((res: any) => {
      this.siteshiftGroup = res.Data.Table;
      if (this.siteshiftGroup.length === 1) {
        this.approvalData.SiteShiftGroup_Code = this.siteshiftGroup[0].SiteShiftGroup_Code;
      }
    });
  }
  getIsAvailableRole(data: any) {
    const list = this.globalSettingList.find(item => item.Code === data);
    const list2 = list ? this.globalSettingList.find(item => item.Code === data).Role : null;
    const roles = JSON.parse(localStorage.getItem('Role'));
    const roleList: number[] = roles.map((roleItem: any) => roleItem.Role_Code);
    return list2 ? roleList.some(item => list2.includes(item)) : false;
  }
  private setActiveTabByRole() {
    const roles = JSON.parse(localStorage.getItem('Role'));
    const roleList: number[] = roles.map((roleItem: any) => roleItem.Role_Code);

    if (this.getIsAvailableRole('MOD52PG195REQVERROLL') && this.IsPrviousApproved === 1) {
      this.tabSet.activeId = 'tab-hr';
      // this.hr = true;
      this.hrverify = true;
      this.ohc = false;
      this.ehs = false;
      this.security = false;
      this.gpvaliditydate = true;
      this.disableenrollment = true;
    } else if (this.getIsAvailableRole('MOD52PG195REQFNSROLL')) {
      this.tabSet.activeId = 'tab-fns';
      // this.hr = false;
      this.hrverify = false;
      this.ohc = false;
      this.ehs = true;
      this.security = false;
      this.gpvaliditydate = false;
      this.disableenrollment = false;
    } else if (this.getIsAvailableRole('MOD52PG195REQOHCROLL')) {
      this.tabSet.activeId = 'tab-ohc';
      // this.hr = false;
      this.hrverify = false;
      this.ohc = true;
      this.ehs = false;
      this.security = false;
      this.gpvaliditydate = false;
      this.disableenrollment = false;
    } else if (this.getIsAvailableRole('MOD52PG195REQSECROLL')) {
      this.tabSet.activeId = 'tab-security';
      // this.hr = false;
      this.hrverify = false;
      this.ohc = false;
      this.ehs = false;
      this.security = true;
      this.gpvaliditydate = false;
      this.disableenrollment = false;
    }

    this.hrTabAllowedUser = roleList.some(item => this.allowedUserForNewRequestForHRTab.includes(item));
  }

  private hideFooterForContractor() {
    const roles = JSON.parse(localStorage.getItem('Role'));
    const roleList: number[] = roles.map((roleItem: any) => roleItem.Role_Code);
    if (roleList.includes(ROLECODE.Contractor)) {
      this.hideVerificationSection = true;
    }
  }

  checksixmonthdate(event) {
    if (typeof event === 'string') {
    } else if (event) {
      const validitydate = new Date(event);
      // newdate = new Date(today.setMonth(today.getMonth() + 6));
      // newdate.setDate(newdate.getDate() - 1);
      const d = new Date(this.approvalData.gatepassValidityDate);
      if (validitydate <= d) {
        // this.gpvaliditydate = true;
      } else {
        this.logHelperService.logError({
          message: 'Can not add validity date greater than six month.'
        });
        this.approvalData.minValidityDate = moment(this.approvalData.gatepassValidityDate);
        return;
      }
    }
  }

  checksixmonthdateeic(event) {
    if (typeof event === 'string') {
    } else if (event) {
      const validitydateeic = new Date(event);
      newdate1 = new Date(today.setMonth(today.getMonth() + 6));
      newdate1.setDate(newdate1.getDate() - 1);
      if (validitydateeic <= newdate1) {
        this.activedate = true;
      } else {
        this.logHelperService.logError({
          message: 'Can not add validity date greater than six month.'
        });
        this.approvalData.gatepassValidityDate = null;
        return;
      }
    }
  }

  addoneyeardate(event) {
    console.log(event)
    console.log(this.nextDueDateCount)
    if (typeof event === 'string') {
    } else if (event) {
      this.approvalData.OHCNextDuedate = event.add(this.nextDueDateCount, 'days');
      // const validitydate = new Date(this.approvalData.CheckUpDate);
      // validitydate.setDate(validitydate.getUTCDate()+this.nextDueDateCount) 
      // this.approvalData.OHCNextDuedate=validitydate
      console.log(this.approvalData.OHCNextDuedate)
      // if (today === validitydate) {
      //   newdate = new Date(today.setMonth(today.getMonth() + 12));
      //   newdate.setDate(newdate.getDate() - 1);
      // } else {
      //   newdate = new Date(validitydate.setMonth(validitydate.getMonth() + 12));
      //   newdate.setDate(newdate.getDate() - 1);
      //   this.approvalData.OHCNextDuedate = newdate;
      //   return;
    }
  }

  addsixmonthdate(event) {
    if (typeof event === 'string') {
    } else {
      const extendeddate = new Date(event);
      if (today === extendeddate) {
        newdate = new Date(today.setMonth(today.getMonth() + 6));
        newdate.setDate(newdate.getDate() - 1);
      } else {
        newdate = new Date(extendeddate.setMonth(extendeddate.getMonth() + 6));
        newdate.setDate(newdate.getDate() - 1);
        this.approvalData.FNSNextScheduledate = newdate;
        return;
      }
    }
  }

  // tslint:disable-next-line: function-name
  IsVerifyEPF($event) {
    this.approvalData.IsVerifyEPF = $event.target.checked;
    if ($event.target.checked === true) {
      this.approvalData.verificationDate = today1;
      this.approvalData.UserforEPF = localStorage.getItem('UserName');
    } else {
      this.approvalData.verificationDate = null;
      this.approvalData.UserforEPF = null;
    }
  }

  // tslint:disable-next-line: function-name
  IsVerifyESIC($event) {
    this.approvalData.IsVerifyESIC = $event.target.checked;
    if ($event.target.checked === true) {
      this.approvalData.ESICVerificationDate = today1;
      this.approvalData.UserforESIC = localStorage.getItem('UserName');
    } else {
      this.approvalData.ESICVerificationDate = null;
      this.approvalData.UserforESIC = null;
    }
  }

  // tslint:disable-next-line: function-name
  IsVerifyDGMS($event) {
    if ($event.target.checked === true) {
      this.approvalData.dgmsVerificationDate = today1;
      this.approvalData.UserforDGMS = localStorage.getItem('UserEmailId');
    } else {
      this.approvalData.dgmsVerificationDate = null;
      this.approvalData.UserforDGMS = null;
    }
  }

  tabvalidation() {
    if (JSON.parse(localStorage.getItem('Role'))[0].Role_Code !== 107) {
      this.activedate = false;
      // this.gpvaliditydate = true;
    } else {
      this.activedate = true;
      // this.gpvaliditydate = false;
    }
  }
}
