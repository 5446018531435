import * as moment from "moment";

export function findTotalMinutes(value: string) {
  if (!value) {
    return 0;
  }
  const [hours, minutes] = value.toString().split(".");
  const minutesValue = minutes || '0';
  return +hours * 60 + (+(minutesValue.length > 1 ? minutesValue : `${minutesValue}0`));
}

export function convertToHourAndMinutes(totalMinutes: number): string {
  if (!totalMinutes) {
    return '0';
  }
  const num = totalMinutes;
  const hours = num / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);

  return `${rhours}.${rminutes}`;
}


export function getMinDateFromCode(data: any[], code1: string, code2: string): string {
  if(!data){
    return '';
  }
  const enableDisableObj = data.find(item => item.Code === code1);
  const daysValueObj = data.find(item => item.Code === code2);
  if (enableDisableObj && daysValueObj && +enableDisableObj.Value > 0) {
    const value = +daysValueObj.Value;
    if (value === 0) {
      return moment().format('YYYY-MM-DD');
    } else {
      return moment().add(value, 'days').format('YYYY-MM-DD');
    }
  }
  return '';
}

export function printFormateCss(): string {

   return `.print-table{border: 0.1px solid gray !important;border-collapse: collapse !important;width: 100% !important;font-family: Arial, Helvetica, sans-serif !important};
    thead tr th, tbody tr th, tfoot tr th, thead tr td, tbody tr td, tfoot tr td {
      border: 0.1px solid gray;
      text-align: center;
      font-family: Arial, Helvetica, sans-serif;
    }
    thead tr th {
      text-align: center;
      padding:5px !important;
      font-size: 10px !impoartant;
      vertical-align: middle !important;
      border: 0.1px solid gray !important;
    }
    thead tr.theadTitle th{
      font-size: 10px;
      background-color: #cdeefa;
      border: 0.1px solid gray !important;
    } 
    tbody tr td{
      padding:5px !important;
      font-size: 10px !important;
      vertical-align: middle !important;
      border: 0.1px solid gray;
    }
    tbody tr td.align-center{
      text-align: left !important;
    }
    .report-title{
      font-size: 12px !important;
      text-align: center;
      padding: 20px;
      border: 0.1px solid gray !important;
    }`;
}