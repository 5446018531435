import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { GridApi, GridOptions } from 'ag-grid-community';
import { GLOBAL } from '../../app.globals';
import { UserManagementService } from '../user-management.service';
import { AgActionCellRendererComponent, AgNumberCountComponent, Ng4LoadingSpinnerService } from 'src/app/core/components';
import { ActivatedRoute, Router } from '@angular/router';
// tslint:disable-next-line:max-line-length
import { AgActionCellLockRendererComponent } from 'src/app/core/components/grid-wrapper/helper/ag-action-cell-lock-renderer/ag-action-cell-lock-renderer.component';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
// tslint:disable-next-line:max-line-length
import { AgActionCellActiveRendererComponent } from 'src/app/core/components/grid-wrapper/helper/ag-action-cell-active-renderer/ag-action-cell-active-renderer.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup } from '@angular/forms';
import { SharedService } from 'src/app/core/services';
// tslint:disable-next-line:max-line-length
import { AgActionCellPermissionRendererComponent } from 'src/app/core/components/grid-wrapper/helper/ag-action-cell-permission-renderer/ag-action-cell-permission-renderer.component';
import { AgGridNg2 } from 'ag-grid-angular';
import { CheckboxCellComponent } from '../checkbox-cell/checkbox-cell.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  @ViewChild('resetpassword') resetpassword: TemplateRef<any>;
  @ViewChild('departmentPer') departmentPer: TemplateRef<any>;
  @ViewChild('accessPer') accessPer: TemplateRef<any>;
  resetpasswordform = new FormGroup({
    newpassword: new FormControl(''),
    confirmpassword: new FormControl(''),
    userpassword: new FormControl(''),
    newpasswordx: new FormControl('')
  });
  departmentPerform = new FormGroup({
    confirmpassword1: new FormControl(''),
    userpassword1: new FormControl('')
  });
  accessPerform = new FormGroup({
    confirmpassword2: new FormControl(''),
    userpassword2: new FormControl('')
  });
  iscontractor = 1;
  // tslint:disable-next-line:variable-name
  UserCode: string;
  // tslint:disable-next-line:variable-name
  UserID: number;
  loggedinUserID: string;
  fullName: number;
  isLocked: boolean;
  isActive: boolean;
  gridAPI: GridApi;
  gridOptions: GridOptions;
  public modalRef: any;
  public resetpw: any;
  public departmentpw: any;
  public accesspw: any;
  userId: string;
  public selectedRow: any[];
  selectedUser: any;
  orignalData = [];
  public roles: any[];

  columnDefs = [
    {
      headerName: '#', field: 'sortCode', width: 10,
      sortable: false, suppressMenu: true, cellRendererFramework: AgNumberCountComponent
    }, {
      headerName: 'Full Name', field: 'FullName', width: 80, sortable: true, filter: true,
      filterParams: { applyButton: true, clearButton: true }
    }, {
      headerName: ' User Code', field: 'UserCode', width: 50, sortable: true, filter: true,
      filterParams: { applyButton: true, clearButton: true }
    }, {
      headerName: 'User Name', field: 'UserName', width: 60, sortable: true, filter: true,
      filterParams: { applyButton: true, clearButton: true }
    }, {
      headerName: 'Email ', field: 'AlternetEmailID', width: 60, sortable: true, filter: true,
      filterParams: { applyButton: true, clearButton: true }
    }, {
      headerName: 'Mobile No', field: 'MobileNo', width: 40, sortable: true, filter: true,
      filterParams: { applyButton: true, clearButton: true }
    },
    // {
    //   headerName: 'Is Organization User', field: 'IsOrganizationUser', width: 50, sortable: true, filter: true,
    //   filterParams: { applyButton: true, clearButton: true }
    // },
    {
      headerName: 'Accountant Status', field: '', width: 25,
      sortable: false, suppressMenu: true, cellRendererFramework: AgActionCellLockRendererComponent
    },
    {
      headerName: 'Reset Password', field: '', width: 50,
      sortable: false, suppressMenu: true, cellRendererFramework: AgActionCellActiveRendererComponent
    },
    {
      headerName: 'Accesss and Permission', field: '', width: 22,
      sortable: false, suppressMenu: true, cellRendererFramework: AgActionCellPermissionRendererComponent
    },
    // {
    //   headerName: 'Is Active', field: '', width: 25,
    //   sortable: false, suppressMenu: true, cellRendererFramework: AgActionCellActiveRendererComponent
    // },
    {
      headerName: 'Action', field: '', width: 25,
      sortable: false, suppressMenu: true, cellRendererFramework: AgActionCellRendererComponent
    }
  ];
  rowData: any;

  constructor(
    private userManagementService: UserManagementService,
    private logHelperService: LogHelperService,
    private modalService: NgbModal,
    private sharedService: SharedService,
    private activatedRoute: ActivatedRoute,
    private loadingService: Ng4LoadingSpinnerService,
    private router: Router) { }

  async ngOnInit() {
    this.getUserList(this.iscontractor);
    this.getUserRoles();
  }

  getUserList(iscontractor: number) {
    this.userManagementService.getUserList(iscontractor).subscribe((res: any) => {
      console.log(res);
      if (res.length > 0) {
        this.rowData = res;
      }
      if (res.Data) {
        this.rowData = res.Data.Table;
      }
      console.log(this.rowData);
    });
  }

  onTabChange(event) {
    this.getUserList(event.nextId);
  }

  editUser(item) {
    debugger;
    this.router.navigate([`/edit-user/${item.UserID}`]);
  }

  deleteUser(item) {

  }

  isLockUser(item) {
    this.UserID = item.UserID;
    this.isLocked = item.IsLocked;
    this.userManagementService.isLockUser(this.UserID, this.isLocked).subscribe((response: any) => {

      if (response.Data === true) {
        this.logHelperService.logSuccess({
          message: 'User is locked successfully'
        });
      } else if (response.Data === false) {
        this.logHelperService.logSuccess({
          message: 'User is unlocked successfully'
        });
      }
      this.getUserList(this.iscontractor);
    });
  }

  isActiveUser(item) {
    this.UserID = item.UserID;
    this.isActive = item.IsActive;
    this.userManagementService.isActiveUser(this.UserID, this.isActive).subscribe((response: any) => {

      if (response.Data === true) {
        this.logHelperService.logSuccess({
          message: 'User is activated successfully'
        });
      } else if (response.Data === false) {
        this.logHelperService.logSuccess({
          message: 'User is de-activated successfully'
        });
      }
      this.getUserList(this.iscontractor);
    });
  }

  resetPassword(item) {
    this.modalRef = this.modalService.open(this.resetpassword, { size: 'lg', backdrop: 'static', keyboard: false });
    this.resetpasswordform.reset();
    this.UserID = item.UserID;
  }

  public resetUserPassword() {
    this.resetpw = this.resetpasswordform.controls['userpassword'].value;
    if ((this.resetpasswordform.controls['userpassword'].value !== '' || this.resetpasswordform.controls['userpassword'].value !== null)
      // tslint:disable-next-line:max-line-length
      && (this.resetpasswordform.controls['confirmpassword'].value !== '' || this.resetpasswordform.controls['confirmpassword'].value !== null)) {
      this.userManagementService.resetPassword(this.UserID, this.resetpw).subscribe((response: any) => {

        if (response.Status === true) {
          this.logHelperService.logSuccess({
            message: 'User password is reset successfully'
          });
          this.resetpasswordform.reset();
        } else if (response.Status === false) {
          this.logHelperService.logSuccess({
            message: 'User password is not reset successfully'
          });
          this.resetpasswordform.reset();
        }
        this.getUserList(this.iscontractor);
        this.modalRef.close('click');
        this.resetpasswordform.reset();
        this.userManagementService.sendMail(this.UserID);
      });
    }
  }

  public clickdepartment($event): void {
    this.userManagementService.getFormData().subscribe((res: any) => {
      this.selectedRow = res;
    });
    const datobject: any = {
      Roles: this.selectedRow,
      UserID: localStorage.getItem('UserID'),
      SelectedUserID: this.UserID
    };
    this.userManagementService.addUserfordepartment(datobject).subscribe((response: any) => {
      if (response) {
        this.logHelperService.logSuccess({
          message: 'Department Access & Permission are successfully added.'
        });
        this.departmentPerform.reset();
        this.router.navigate(['user-management/user-list']);
      } else {
        this.logHelperService.logError({
          message: 'Department Access & Permission are not added!'
        });
        this.departmentPerform.reset();
        this.router.navigate(['user-management/user-list']);
      }
    });
  }

  public clickaccess($event): void {
    // this.userManagementService.getFormData().subscribe((res: any) => {
    //   this.selectedRow = res;
    // });
    // this.userManagementService.addUserfordepartment(this.selectedRow).subscribe((response: any) => {
    //   if (response) {
    //     alert('Yes');
    //   }
    // });
    // this.onSave();
  }

  public onCloseModel(): void {
    this.modalRef.close('click');
  }

  onAutoGeneratePassword() {
    const pswd = this.sharedService.generateRandomPassword(10);
    this.resetpasswordform.controls['userpassword'].setValue(pswd);
    this.resetpasswordform.controls['confirmpassword'].setValue(pswd);
  }

  departmentPermission(item) {
    this.modalRef = this.modalService.open(this.departmentPer,
      { size: 'lg', backdrop: 'static', keyboard: false, windowClass: 'modal-full-size' });
    this.resetpasswordform.reset();
    this.UserID = item.UserID;
    this.fullName = item.FullName;
    this.selectedUser = item;
  }

  accessPermission(item) {
    this.modalRef = this.modalService.open(this.accessPer,
      { size: 'lg', backdrop: 'static', keyboard: false, windowClass: 'modal-full-size' });
    this.resetpasswordform.reset();
    this.UserID = item.UserID;
    this.selectedUser = item;
  }

  public getUserRoles(): void {
    this.loggedinUserID = localStorage.getItem('UserID');
    this.userManagementService.getUserRoles(this.loggedinUserID).subscribe((response: any) => {
      this.roles = response;
    });
  }

}
