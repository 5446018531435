import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { GLOBAL } from "src/app/app.globals";
import { map } from "rxjs/operators";
import { of } from "rxjs";
declare var $: any;

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: "root",
})
export class GatePassRequestsService {
  constructor(private httpClient: HttpClient) { }

  public requestType: any = '';
  getRequestTypeText(stepData) {
    if (stepData) {
      switch (stepData.requestType) {
        case 105:
          this.requestType = 'Cancel Request';
          break;
        case 104:
          this.requestType = 'Renew Request';
          break;
        case 103:
          this.requestType = 'New Request';
          break;
        default:
          break;
      }
    }
  }

  getAllGatePassRequests(params) {
    const body = JSON.stringify(params);
    httpOptions["body"] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_GATEPASS_REQUESTS}`,
      httpOptions
    );
  }

  /**
   * @author Ashok Yadav.
   * @method getAllContractorData
   * @description Invoke this method and it is return `getAllContractorData` observable.
   */
  //   public getAllContractorData(): Observable<any> {
  //     return this.httpClient.get(GLOBAL.APIS.LABOUR_MANAGEMENT.GET_ALL_CONTRACTORS);
  //   }

  public getAllContractorData(
    excludeContractorCode: string = ""
  ): Observable<any> {
    return this.httpClient
      .get(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_ALL_CONTRACTORS}?search=&sDate=&eDate=&selectedField=&isFilter=&isMain=
    &ExcludeContractor_Code=${excludeContractorCode}`);
  }

  public getContractorsbydefault(userId: any, roleCode: any): Observable<any> {
    return this.httpClient.get(
      `${GLOBAL.APIS.LICENSE.GET_CONTRACTORSBY_DEFAULT}?UserId=${userId}&RoleCode=${roleCode}`
    );
  }

  /**
   * @author Ashok Yadav.
   * @method getWorkOrderByContractor
   * @description Invoke this method and it is return `getAllContractorData` observable.
   */
  public getWorkOrderByContractr(data: any): Observable<any> {
    return this.httpClient.get(
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_PASS_MANAGEMENT}?ID=${data.contractorVendorCode}&ReqType=${data.requestType}`
    );
  }

  /**
   * @author Ashok Yadav.
   * @method getLicenseByContractor
   * @description Invoke this method and it is return `getAllContractorData` observable.
   */
  public getLicenseByContractor(data: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    // return this.httpClient.get(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_PASS_MANAGEMENT1}
    //   ?ID=${data.parentContractorVendorCode}&ReqType=${data.requestType}&workordercode=
    //   ${data.parentWorkOrderCode}`);
    return this.httpClient
      .get(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_PASS_MANAGEMENT1}GetLicenseByContractor?ID=
    ${data.parentContractorVendorCode}&ReqType=${data.requestType}&workordercode=${data.parentWorkOrderCode}`);
  }

  /**
   * @author Ashok Yadav.
   * @method getCheckPlants
   * @description Invoke this method and it is return `getAllContractorData` observable.
   */
  public getCheckPlants(data: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(
      GLOBAL.APIS.LABOUR_MANAGEMENT.GET_PASS_MANAGEMENT2 +
      "CheckPlants?contractorcode=" +
      data.parentContractorVendorCode +
      "&typeWise=" +
      2 +
      "&workoderordercode=" +
      data.parentWorkOrderCode
    );
  }

  /**
   * @author Ashok Yadav.
   * @method getCheckPlants
   * @description Invoke this method and it is return `getAllContractorData` observable.
   */
  public getCheckLicenseRequired(data: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(
      GLOBAL.APIS.LABOUR_MANAGEMENT.GET_PASS_MANAGEMENT1 +
      "CheckLicenseRequired?Reqtype=" +
      data.requestType +
      "&plantcode=" +
      data.plantCode +
      "&workOrderCode=" +
      data.parentWorkOrderCode
    );
  }

  /**
   * @author Ashok Yadav.
   * @method getCheckEsic
   * @description Invoke this method and it is return `getCheckEsic` observable.
   */
  public getCheckEsic(data: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(
      GLOBAL.APIS.LABOUR_MANAGEMENT.GET_PASS_MANAGEMENT +
      "CheckESIC?contractorcode=" +
      data.parentContractorCode +
      "&plantcode=" +
      data.plantCode
    );
  }

  /**
   * @author Ashok Yadav.
   * @method getCompanyPolicy
   * @description Invoke this method and it is return `getCompanyPolicy` observable.
   */
  public getCompanyPolicy(data: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(
      GLOBAL.APIS.LABOUR_MANAGEMENT.GET_PASS_MANAGEMENT1 +
      "CheckCompanyPolicy?workoderordercode=" +
      data.parentWorkOrderCode
    );
  }

  /**
   * @author Ashok Yadav.
   * @method getAllLabour
   * @description Invoke this method and it is return `getAllLabour` observable.
   */
  public getAllLabour(body: any): Observable<any> {
    // httpOptions['body'] = body;
    return this.httpClient.post(
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_PASS_MANAGEMENT1}GetAllLabour`,
      body
    );
  }
  getContractorVendorData() {
    const model = {
      Search: "",
      sDate: "",
      eDate: "",
      selectedField: "",
      isMain: "",
      contractor_Code: "",
      ExcludeContractor_Code: "",
      workOrderCode: "",
      // UserID: localStorage.getItem('UserID'),
      //  RoleCode: JSON.parse(localStorage.getItem('Role'))[0].Role_Code,
    };

    const url = `${GLOBAL.APIS.WORK_ORDER.GET_CONTRACTORS}?search=&sDate=&eDate=&selectedField=&isMain=&
    ExcludeContractor_Code=${model.ExcludeContractor_Code}&workOrderCode=${model.workOrderCode}&contractor_Code=`;
    // console.log(url);
    return this.httpClient
      .get<any[]>(url)
      .pipe(map((response: any) => response));

    // return this.httpClient.post(`${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_CONTRACTOR_VENDOR_DATA}`, model);
  }

  getContractorDetails(ID) {
    const model = {
      UserId: ID,
    };
    return this.httpClient.post(
      `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_CONTRACTOR_DETAIL}`,
      model
    );
  }
  // const body = JSON.stringify(params);
  // httpOptions['body'] = body;
  // return this.httpClient.request(
  // GLOBAL.HTTP_GET,
  // `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_CONTRACTOR_VENDOR_DATA}`,
  // httpOptions
  // );
  // }

  /**
   * @author Ashok Yadav.
   * @method getWCPolicy
   * @description Invoke this method and it is return `getWCPolicy` observable.
   */
  public getWCPolicy(workorderCode: string): Observable<any> {
    return this.httpClient.get(
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_PASS_MANAGEMENT3}GetWCPolicy?workoderordercode=${workorderCode}`
    );
  }

  /**
   * @author Ashok Yadav.
   * @method getSubContractor
   * @description Invoke this method and it is return `getWCPolicy` observable.
   */
  public getSubContractor(data: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(
      GLOBAL.APIS.LABOUR_MANAGEMENT.GET_PASS_MANAGEMENT +
      "GetSubContractor?contractorCode=" +
      data.parentContractorCode +
      "&workorderCode=" +
      data.workoderordercode
    );
  }

  /**
   * @author Ashok Yadav.
   * @method getSubWorkOrder
   * @description Invoke this method and it is return `getSubWorkOrder` observable.
   */
  public getSubWorkOrder(data: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(
      GLOBAL.APIS.LABOUR_MANAGEMENT.GET_PASS_MANAGEMENT +
      "GetSubWorkOrderByContractor?Reqtype=" +
      data.requestType +
      "&contractorCode=" +
      data.parentContractorCode +
      "&subContractorCode=" +
      data.subContractorCode +
      "&workorderCode=" +
      data.workOrderCode
    );
  }

  /**
   * @author Ashok Yadav.
   * @method getSubLicense
   * @description Invoke this method and it is return `getSubLicense` observable.
   */
  public getSubLicense(data: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(
      GLOBAL.APIS.LABOUR_MANAGEMENT.GET_PASS_MANAGEMENT +
      "GetSubLicenseByContractor?Reqtype=" +
      data.requestType +
      "&contractor=" +
      data.parentContractorCode +
      "&subcontractor=" +
      data.subContractorCode +
      "&subworkordercode=" +
      data.subWorkOrderCode +
      "&workordercode=" +
      data.workOrderCode
    );
  }

  /**
   * @author Ashok Yadav.
   * @method insertGatePassRequest
   * @description Invoke this method and it is return `insertGatePassRequest` observable.
   */
  public insertGatePassRequest(data: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.post(
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_PASS_MANAGEMENT1}InsertGatePassRequest`,
      data
    );
  }

  public insertGatePassRequestRenew(data: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.post(
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_PASS_MANAGEMENT1}InsertGatePassRenewRequest`,
      data
    );
  }

  public insertGatePassRequestCancel(data: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.post(
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_PASS_MANAGEMENT1}InsertGatePassCancelRequest`,
      data
    );
  }

  /**
   * @author Ashok Yadav.
   * @method getLabourData
   * @description Invoke this method and it is return `getLabourData` observable.
   */
  public getLabourManagement(data: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.post(
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LABOUR_MANAGEMENT}getLabourData`,
      data
    );
  }

  /**
   * @author Ashok Yadav.
   * @method getSelectionValueOfLabour.
   * @description Invoke this method and it is return `getSelectionValueOfLabour` observable.
   */
  public getSelectionValueOfLabour(body: any): Observable<any> {
    return this.httpClient.post(
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_PASS_MANAGEMENT1}GetselectionValueOfLabour`,
      body
    );
  }

  // getWorkOrderByContractor(ID) {
  //   const queryString = $.param({
  //     ID: JSON.stringify(ID)
  //   });
  //   return this.httpClient.request(
  //     GLOBAL.HTTP_GET,
  //     `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_WO_CONTRACTOR_DATA}?${queryString}`,
  //     httpOptions
  //   );
  // }

  public getWorkOrderByContractor(
    contractorCode: number,
    plantcode: number
  ): Observable<any> {
    const url = `${GLOBAL.APIS.WORK_ORDER.GET_WORK_ORDER_BY_CONTRACTOR}?contractorcode=${contractorCode}&plantcode=${plantcode}`;
    return this.httpClient
      .get<any>(url)
      .pipe(map((response: any) => response.Data));
  }

  getLicenseByWO(contractorCode, workorderCode) {
    // const queryString = $.param({
    //   Contractorvendor: JSON.stringify(contractorCode),
    //   Workorder: JSON.stringify(workorderCode)
    // });
    // return this.httpClient.request(
    //   GLOBAL.HTTP_GET,
    //   `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_LICENCE_BY_WO}?${queryString}`,
    //   httpOptions
    // );

    const url = `${GLOBAL.APIS.LICENSE.GET_LICENSE}?search=&sDate=&eDate=&selectedField=&filterType=&condition=&State_Code=
                  &Status_Code=&Plant_Code=&IsActive=&NatureOfLicense_Code=&NatureOfWork_Code=&TotalAccupied=
                  // tslint:disable-next-line:max-line-length
&Contractor_Code=${contractorCode}&SubContractor_Code=&WorkOrder_Code=${workorderCode}&SubWorkOrder_Code=&isContractorType=&UserId=`;
    return this.httpClient
      .get(url)
      .pipe(map((response: any) => response.Data.Table));
  }

  getEcpolicyByWO(workorderCode) {
    const queryString = $.param({
      Workorder: JSON.stringify(workorderCode),
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_EC_POLICY_BY_WO}?${queryString}`,
      httpOptions
    );
  }

  public getContractorSubContractorData(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_RPT_FILTER_ContractorAndSubContractor`;
    return this.httpClient
      .post<any>(url, data)
      .pipe(map((response: any) => response));
    //  return of(this.totalPlantWiseData());
  }

  public getWorkOrdersByContractorCode(
    contractorCode: any,
    plantcode: number
  ): Observable<any> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}GetChildWorkorderBySubContractor`;
    const data = {
      contractorCodes: contractorCode,
      plantCode: plantcode,
    };
    return this.httpClient
      .post<any>(url, data)
      .pipe(map((response: any) => response));
  }

  public getEcPolicyFilterData(data: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    // return this.httpClient.get(GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_CONTRACTORS_WCPOLICIES_DATA
    return this.httpClient.get(
      `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_CONTRACTORS_COMPANYECPOLICIES_DATA}?search=${data.search}`
    );
  }

  getLicenses(contractorCode: any) {
    const url = `${GLOBAL.APIS.LICENSE.GET_LICENSE}?search=&sDate=&eDate=&selectedField=&filterType=&condition=&State_Code=
                &Status_Code=&Plant_Code=&IsActive=&NatureOfLicense_Code=&NatureOfWork_Code=&TotalAccupied=
                &Contractor_Code=${contractorCode}&SubContractor_Code=&WorkOrder_Code=&SubWorkOrder_Code=&isContractorType=&UserId=`;
    return this.httpClient
      .get(url)
      .pipe(map((response: any) => response.Data.Table));
  }

  public getEcPolicyFilterDataSP(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}GetEcpolicyByCon`;
    return this.httpClient
      .post(url, data)
      .pipe(map((response: any) => response));
  }

  getSubContractorByWOData(contractorCode, workorderCode) {
    const queryString = $.param({
      contractorCode: JSON.stringify(contractorCode),
      workOrder: JSON.stringify(workorderCode),
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_SUBCONT_BY_WO}?${queryString}`,
      httpOptions
    );
  }

  getSubWorkOrderBySCData(contractorCode) {
    const queryString = $.param({
      subcontractorCode: JSON.stringify(contractorCode),
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_SUB_WORKORDER_BY_SC}?${queryString}`,
      httpOptions
    );
  }

  getLicenseBySubWO(workorderCode) {
    const queryString = $.param({
      SubWorkordercode: JSON.stringify(workorderCode),
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_LICENCE_BY_SUB_WO}?${queryString}`,
      httpOptions
    );
  }

  getSubEcpolicyByWO(workorderCode) {
    const queryString = $.param({
      SubWorkordercode: JSON.stringify(workorderCode),
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_SUB_EC_POLICY_BY_WO}?${queryString}`,
      httpOptions
    );
  }

  getALLDepartments() {
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_FILTER_DEPARTMENTS}`,
      httpOptions
    );
  }

  getALLWorkSiteCategories() {
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_ALL_WS_CATEGORIES}`,
      httpOptions
    );
  }

  getALLPlants() {
    const model = {
      Search: "",
      Company: "",
      State: "",
      City: "",
      Country: "",
      UnitType: "",
      OwnerShip: "",
      LocatedAt: "",
      IsESICApplicable: "",
    };
    return this.httpClient.post(
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_ALL_PLANTS}`,
      model
    );
  }

  // return this.httpClient.request(
  //  GLOBAL.HTTP_GET,
  // `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_ALL_PLANTS}?Search=&Company=&StateCode=&CityCode=&CountryCode=
  // &UnitType=&Ownership=&LocatedAt=&ISESICApplicable=`,
  //  httpOptions
  // );
  // }

  //   public getAllContractorData(excludeContractorCode: string = ''): Observable<any> {
  //     return this.httpClient.get(`${GLOBAL.APIS.LABOUR_MANAGEMENT.
  //     GET_ALL_CONTRACTORS}?search=&sDate=&eDate=&selectedField=&isFilter=&isMain=
  //     &ExcludeContractor_Code=${excludeContractorCode}`);
  //   }

  getALLSkills() {
    const model = {
      Search: "",
      Plant_Code: "",
      ParentSkill_Code: "",
      Trade_Code: "",
    };
    return this.httpClient.post(
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_ALL_SKILLS}`,
      model
    );
  }

  // return this.httpClient.request(
  // GLOBAL.HTTP_GET,
  // `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_ALL_SKILLS}?Search=&PlantCode=&ParentSkillCode=&TradeCode=`,
  // httpOptions
  // );
  // }

  getGateHrApprovalData(hrLabourCode, hrRequestTypeCode, hrApprovalCode) {
    const queryString = $.param({
      HRlabourCode: hrLabourCode,
      requestType_Code: hrRequestTypeCode,
      HRapprovalcode: hrApprovalCode,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_GATE_HR_APPROVAL_DATA}?${queryString}`,
      httpOptions
    );
  }

  getGateApprovalData(labourCode, isMulti, requestcode) {
    const queryString = $.param({
      labourCode,
      requestcode,
      isMulti,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_GATE_APPROVAL_DATA}?${queryString}`,
      httpOptions
    );
  }

  getDocumentForLabour(labourCode) {
    const queryString = $.param({
      labourCode,
      PageCode: 0,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_DOC_FOR_LABOUR}?${queryString}`,
      httpOptions
    );
  }

  getLabourVerifyData(params) {
    const body = JSON.stringify(params);
    httpOptions["body"] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LABOUR_VERIFY_DATA}`,
      httpOptions
    );
  }

  getDocumentForLabourVerify(labourCode) {
    const queryString = $.param({
      labourCode,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_DOC_VERIFY_DATA}?${queryString}`,
      httpOptions
    );
  }

  getHistoryData(labourCode) {
    const queryString = $.param({
      labourCode,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_HISOTRY_OF_LABOUR}?${queryString}`,
      httpOptions
    );
  }

  getAllSections() {
    const model = {
      Search: null
    };
    return this.httpClient.post(
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_ALL_SECTION}`,
      model
    );
  }
  // return this.httpClient.request(
  // GLOBAL.HTTP_GET,
  // `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_ALL_SECTION}?${queryString}`,
  // httpOptions
  // );
  // }

  labourVerifications(labourCode, updatedULCCode, isVerifyDoc, userID) {
    const body = {
      updatedULCCode,
      LabourCode: labourCode,
      IsVerifyDoc: isVerifyDoc,
      UserID: userID,
    };
    if (httpOptions["body"]) {
      delete httpOptions["body"];
    }
    httpOptions["params"] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.POST_LABOUR_VERIFICATIONS}`,
      httpOptions
    );
  }

  actionSaveMulti(objgatepass) {
    const body = JSON.stringify(objgatepass);
    if (httpOptions["params"]) {
      delete httpOptions["params"];
    }
    httpOptions["body"] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.POST_ACTION_SAVE_APPROVE}`,
      httpOptions
    );
  }

  sendMailForApproval(objgatepass) {
    const body = JSON.stringify(objgatepass);
    httpOptions["body"] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.SEND_MAIL_FOR_APPROVAL}`,
      httpOptions
    );
  }

  getShiftGroupData() {
    const model = {
      Search: "",
      Plant_Code: "",
    };
    return this.httpClient.post(
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_SHIFT_GROUP_DATA}`,
      model
    );
  }

  WorkmanSalaryMaster(data: {
    UEC: number;
    IsLabour: number;
    RequireType: number;
    ActionType: number;
    UserId: number;
  }) {
    console.log(data);
    // debugger;
    return this.httpClient.post(
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.CLPMS_SP_LIST_GATEPASS_PAYROLLHANDENTRY}`,
      data
    );
  }

  gatepasspPayrollHandentry(data: {
    UEC: number;
    Component_Code: number;
    EffectiveDate: Date;
    Amount: number;
    UserId: number;
    HandEntryValueId: number;
  }) {
    return this.httpClient.post(
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.CLPMS_SP_LIST_GATEPASS_PAYROLLHANDENTRY}`,
      data
    );
  }

  insertUpdatePayrollHandentry(data: any[]) {
    return this.httpClient.post(
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.CLPMS_SP_INSERTUPDATE_PAYROLLHANDENTRY}`,
      data
    );
  }

  insertUpdatePayrollHandentryById(data: {
    UEC: number;
    IsLabour: number;
    RequireType: number;
    ActionType: number;
    UserId: number;
  }) {
    return this.httpClient.post(
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.CLPMS_SP_INSERTUPDATE_PAYROLLHANDENTRY}/?id=${data.UserId}`,
      data
    );
  }

  // tslint:disable-next-line: prefer-template
  // return this.httpClient.get(GLOBAL.APIS.LABOUR_MANAGEMENT.GET_SHIFT_GROUP_DATA + '?Search=&PlantCode=');
  // }

  getSiteShiftGroupData() {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(
      GLOBAL.APIS.LABOUR_MANAGEMENT.GET_SITE_SHIFT_GROUP_DATA + "?Search="
    );
  }

  // getAllState() {
  // //   const model = {
  // //     Search: '',
  // //     Country_Code: '',
  // //   };
  // //   return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_State}`, model);
  // // }
  // return this.httpClient.request(
  // GLOBAL.HTTP_GET,
  // `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_State}?Search=&CountryCode=`,
  // httpOptions
  // );
  // }

  public getAllState(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_STATE}?Search=&CountryCode=`;
    console.log(url);
    return this.httpClient
      .get<any[]>(url)
      .pipe(map((response: any) => response.Data.Table));
  }

  getCommonSpUserPlantAcces() {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_COMMON_SP_USERPLANTACCES}`

    let payload = {
      "UserID": GLOBAL.USER_ID,
      "RequireType": 1,
      "SiteMIL_Code": null,
      "ActionType": null,
    }

    return this.httpClient.post<any[]>(url, payload)
  }

  getLeaveTypeData() {
    const model = {
      Search: "",
      UserID: localStorage.getItem("UserID"),
    };
    return this.httpClient.post(
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LeaveType_LeaveBalance}`,
      model
    );
  }

  //    const queryString = $.param({
  //    Search: '',
  //  UserID: localStorage.getItem('UserID')
  // });
  // return this.httpClient.request(
  // GLOBAL.HTTP_GET,
  // `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LeaveType_LeaveBalance}?${queryString}`,
  // httpOptions
  // );
  // }

  getMonth() {
    const model = {
      Search: "",
    };
    return this.httpClient.post(
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_PAYROLLMONTH}`,
      model
    );
  }

  //    const queryString = $.param({
  //    Search: null
  // });
  // return this.httpClient.request(
  // GLOBAL.HTTP_GET,
  // `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_PAYROLLMONTH}?${queryString}`,
  // httpOptions
  // );
  // }

  // getBalance(contractorcode) {
  //   const queryString = $.param({
  //     // contractorcode: contractorcode
  //     contractorcode
  //   });
  //   return this.httpClient.request(
  //     GLOBAL.HTTP_GET,
  //     `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LeaveTypes}?${queryString}`,
  //     // `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LeaveTypes}?`,
  //     httpOptions
  //   );
  // }

  // public getBalance(search, userID, roleCode, gatepass): Observable<any[]> {
  //   const url = `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LISING_LEAVEBALANCE}`;
  //   return this.httpClient.post(url, { Search : search, UserID : userID, RoleCode : roleCode, Gatepass : gatepass }).
  //     pipe(
  //       map((response: any) => response.Data));
  // }

  public getBalance(search, userID, roleCode, gatepass): Observable<any[]> {
    const url = `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LISING_LEAVEBALANCE}`;
    return this.httpClient
      .post(url, { search, userID, roleCode, gatepass })
      .pipe(map((response: any) => response));
  }

  addLeaveBalance(leavedata): Observable<Object> {
    const body = JSON.stringify(leavedata);
    httpOptions["body"] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.POST_INSERT_LEAVEBALANCE_DATA}`,
      httpOptions
    );
  }

  // tslint:disable-next-line: space-in-parens
  approveMultipleData(labourData): Observable<Object> {
    const body = JSON.stringify(labourData);
    httpOptions["body"] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.APPROVE_MULTIPLE_LABOUR}`,
      httpOptions
    );
  }

  suspendLabour(data) {
    const model = {
      SuspensionType: data.SuspensionType,
      FromDate: data.FromDate,
      ToDate: data.ToDate,
      UserID: data.UserID,
      ULC: data.ULC,
      GatePassNo: data.GatePassNo,
      Reason: data.Reason,
    };
    return this.httpClient.post(
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.SUSPEND_LABOUR}`,
      model
    );
  }
  getsuspendLabour(data) {
    const model = {
      ULC: data,
    };
    return this.httpClient.post(
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_SUSPEND_LABOUR}`,
      model
    );
  }

  getMaxValidityDate(reqeustObj: any): Observable<any> {
    return this.httpClient.post(
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_MAXIMUM_VALIDITY_DATE}`,
      reqeustObj
    );
    // return of({date: '2022-01-02'});
    // return of(null)
  }

  editGatePassRequetsData(reqeustObj: any): Observable<any> {
    return this.httpClient.post(
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.CLPMS_SP_Update_GatepassRequestDetails}`,
      reqeustObj
    );
  }
  saveShiftGroupFroEditRequest(reqeustObj: any): Observable<any> {
    return this.httpClient.post(
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.CLPMS_SP_ShiftGroup_Insert_Update}`,
      reqeustObj
    );
  }

  getGateDetails(data: any) {
    return this.httpClient.post(
      GLOBAL.APIS.LABOUR_MANAGEMENT.CLPMS_SP_GatePassDetails,
      data
    );
  }

  public getUserSiteCode(data: any): Observable<any> {
    return this.httpClient.post(
      `${GLOBAL.APIS.COMMON.COMMON_SP_UserPlantAccess}`,
      data
    );
  }

  public globalSettingCode(code: string) {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_GLOBAL_SETTING}?Code=${code}`
    return this.httpClient.get(url)
  }

  public insertDeleteDoc(data: any) {
    return this.httpClient.post(
      `${GLOBAL.APIS.COMMON.Common_SP_InsertDelete_Doc}`,
      data
    );
  }
  public getRolePermission(data: any) {
    return this.httpClient.post(
      `${GLOBAL.APIS.COMMON.CheckPrviousLevelApproval4HR}`,
      data
    );
  }
}
