import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as moment from "moment";
import { forkJoin, Observable, Subject } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
import { GLOBAL } from "src/app/app.globals";
import { SharedService } from "src/app/core/services";
import { LogHelperService } from "src/app/core/services/log-helper.service";
import { ContractorType, GatePassAccociateInformation, GatepassRequestProcess } from "./gatepass-request-process.model";
import { GatepassRequestProcessService } from "./gatepass-request-process.service";
import { GatePassRequestsService } from "../gate-pass-requests.service";

const browserWindow = window || {};
const browserWindowEnv = browserWindow['__env'] || {};

@Component({
  selector: 'app-gatepass-request-process',
  templateUrl: './gatepass-request-process.component.html',
  styleUrls: ['./gatepass-request-process.component.css'],
  providers: [GatepassRequestProcessService]
})
export class GatePassRequestProcessComponent implements OnInit {

  @Input() public requestType: number;
  @Input() public contractorList: any[] = [];
  @Input() public contractorInformation: any;
  @Input() public userSiteCode: any;

  @Output() public handleClose: EventEmitter<void>;
  public isContractor: boolean;
  public allStepDataObj: GatepassRequestProcess = {};
  public currentStep: number = 0;
  public workorderList: any[] = [];
  public plantList: any[] = [];
  public licenseList: any[] = [];
  public wcPolicyList: any[] = [];
  public associateList: any[] = [];
  public associateCount: string;
  public isResingationInput: boolean;
  public isDisableWorkOrderPrevBtn: boolean;
  public isDisableContractorPrevBtn: boolean;
  public globalSettings: any[] = [];
  public alertMessages: any[] = [];
  public selectedPlant: any;
  public licenseReqObj: any;
  public objectAfterPlantSelect: any;
  public stateCapacityCheck: any;
  public checkConditionForRemaining: any;
  selectedWorkOrderDetail: any;
  selectedPlantDetail: any;
  workOrderCode: any;
  contractorCode: any;

  private destroy: Subject<void>;

  private useRoles: any[] = [];

  constructor(
    private sharedService: SharedService,
    private gatePassRequestProcessService: GatepassRequestProcessService,
    private logHelperService: LogHelperService,
    public gatepassService: GatePassRequestsService
  ) {
    this.handleClose = new EventEmitter<void>();
    this.destroy = new Subject<void>();
    this.useRoles = this.getCurrentUserRoles();
    this.isContractor = this.useRoles.some(item => item.Role_Code === 119);
    if (this.isContractor) {
      this.allStepDataObj = { ...this.allStepDataObj };
    }
  }

  public ngOnInit(): void {

    this.allStepDataObj = { ...this.allStepDataObj, requestType: this.requestType };
    this.gatepassService.getRequestTypeText(this.allStepDataObj);
    this.checkGlobalSettingsForVisibility();
    this.getAlertMessageFromGlobalSource();
    // this.stateCapacityCheck = this.globalSettings.find(item => item.Code === browserWindowEnv.STATE_CAPACITY_EXCEED_STATUS).Value;
    this.stateCapacityCheck = this.globalSettings.find(item => item.Code === 'MOD52PG195REQLICCAP').Value;
    console.log(this.stateCapacityCheck);
    this.checkConditionForRemaining = this.globalSettings.find(item => item.Code === 'MOD52PG195REQWOCAP').Value;
  }

  public onAccociateInformationChange({ contractorType }: GatePassAccociateInformation): void {
    this.allStepDataObj = { ...this.allStepDataObj, contractorType: contractorType, Role_Code: this.useRoles[0].Role_Code };
    if (this.isContractor) {
      this.getContractors(1, true);
      this.allStepDataObj = { ...this.allStepDataObj, contractorType: contractorType, ContractorVendor_Code: this.contractorInformation.ContractorVendor_Code };
      this.currentStep = 3;
    } else {
      this.allStepDataObj = { ...this.allStepDataObj, contractorType: contractorType };
      this.currentStep = 2;
    }
  }

  public onContractorSelection(data: any): void {
    const newObj: GatepassRequestProcess = {
      ...(this.allStepDataObj.isSubContractorFlowStarted && { SubContractor_Code: data.ContractorVendor_Code, SubCompany: data.Company }),
      ...(!this.allStepDataObj.isSubContractorFlowStarted && { ContractorVendor_Code: data.ContractorVendor_Code, Company: data.Company })
    };
    this.allStepDataObj = { ...this.allStepDataObj, ...newObj };
    const requestObj = this.createWorkOrderListRequest(this.allStepDataObj);
    this.gatePassRequestProcessService.getWorkOrders(requestObj).subscribe((res: any) => {
      if (res) {
        this.workorderList = res;
        this.currentStep = 3;
      }
    });
  }

  oldSelectedWorkOrderDetails: any;
  isFromMainWorkOrder: boolean = false;
  public onWorkorderSelection(item: any): void {
    debugger
    this.oldSelectedWorkOrderDetails = this.selectedWorkOrderDetail;
    this.selectedWorkOrderDetail = item
    const finalObj: GatepassRequestProcess = {
      ...this.allStepDataObj,
      ...(this.allStepDataObj.isSubContractorFlowStarted && { SubWorkOrder_Code: item.WorkOrder_Code, SubWorkOrderNo: item.WorkOrderNo, SubPlant: item.Plant }),
      ...(!this.allStepDataObj.isSubContractorFlowStarted && { WorkOrder_Code: item.WorkOrder_Code, WorkOrderNo: item.WorkOrderNo, Plant: item.Plant })
    };
    this.allStepDataObj = { ...this.allStepDataObj, ...finalObj };
    if (this.allStepDataObj.requestType === 105 && this.allStepDataObj.contractorType != 'SUB_CONTRACTORS_MAN') {
      const data = this.globalSettings.find(item => item.Code === 'MOD152PG198C100FV2');
      if (data) {
        this.associateCount = data.Value
      }
      this.currentStep = 9;
      const requestObj = this.createGatepassListRequest(this.allStepDataObj);
      this.gatePassRequestProcessService.getGatepassList(requestObj).subscribe((res: any[]) => {
        this.associateList = res;
      });
      return;
    } else if (this.allStepDataObj.requestType === 105 && this.allStepDataObj.contractorType == 'SUB_CONTRACTORS_MAN') {
      if (this.isFromMainWorkOrder == true) {
        const data = this.globalSettings.find(item => item.Code === 'MOD152PG198C100FV2');
        if (data) {
          this.associateCount = data.Value
        }
        this.currentStep = 9;
        const requestObj = this.createGatepassListRequest(this.allStepDataObj);
        this.gatePassRequestProcessService.getGatepassList(requestObj).subscribe((res: any[]) => {
          this.associateList = res;
        });
        return;
      } else {
        this.getContractors(0);
        this.currentStep = 2;
        this.isFromMainWorkOrder = true;
        return;
      }

    }
    this.objectAfterPlantSelect = finalObj;
    this.licenseReqObj = this.createLicenseListRequest(finalObj);
    const reqObj = this.createPlantListRequest(item.WorkOrder_Code);
    this.gatePassRequestProcessService.getPlantList(reqObj).subscribe((res: any) => {
      this.plantList = res;
      console.log(res)
      if (this.plantList.length === 1) {
        this.onPlantSelection(this.plantList[0]);
      } else {
        this.currentStep = 4;
      }
    })
  }

  checkCondition: any;
  public onPlantSelection(data: any): void {
    this.selectedPlantDetail = data
    this.selectedPlant = data;
    this.gatePassRequestProcessService.getLicenseByContractor(this.licenseReqObj).subscribe((res: any[]) => {
      this.checkCondition = this.globalSettings.find(item => item.Code === 'MOD52PG195REQLICCAP').Value;
      if (res.length < 1) {
        // let msg = this.alertMessages.find(item => item.Short_Code === 'MSG195_8').MSG;
        // msg = msg.replaceAll('{State}', data.State);
        // msg = msg.replaceAll('{Value}', data.LicenseMaxValue);
        // if (this.selectedWorkOrderDetail.ActivePerson < this.selectedPlant.LicenseMaxValue) {
        //   this.licenseList = [];
        //   this.currentStep = 5;
        // } else if (this.selectedWorkOrderDetail.ActivePerson >= this.selectedPlant.LicenseMaxValue) {
        //   if (this.checkCondition == "1") {
        //     this.logHelperService.logError({
        //       message: msg,
        //       html: true
        //     })
        //   } else if (this.checkCondition == "2") {
        //     this.logHelperService.logError({
        //       title: 'Warning!',
        //       message: msg,
        //       html: true
        //     }, 'warning', 'Go to Next', true).then((confirmRes) => {
        //       if (confirmRes && confirmRes.value) {
        //         this.licenseList = [];
        //         this.currentStep = 5;
        //       }
        //     })
        //   } else {
        //     this.licenseList = [];
        //     this.currentStep = 5;
        //   }
        // }
        this.licenseList = res;
        this.currentStep = 5;
      } else {
        if (res.length === 1) {
          this.licenseList = res;
        } else {
          this.licenseList = res.filter(element => {
            if (element.Plant_Code == this.selectedPlant.ParentPlant_Code) {
              return element;
            }
          });


          // if(activePersonSum > this.selectedPlant.LicenseMaxValue && this.checkConditionForRemaining !== "2") {
          //   this.logHelperService.logError({
          //     message: `As per CLRA, ${this.selectedPlant.State} state has allowed only ${this.selectedPlant.LicenseMaxValue} gate pass without Labour License`
          //   })
          // }
        }
        // const Remaining = this.licenseList[0].Person - this.licenseList[0].ActivePerson;
        // this.licenseList[0].Remaining = Remaining;
        this.licenseList.forEach(license => {
          Object.assign(license, { Remaining: license.Person - license.ActivePerson })
          if (this.selectedWorkOrderDetail.ActivePerson > this.selectedPlant.LicenseMaxValue && this.checkConditionForRemaining !== "2") {
            this.logHelperService.logError({
              message: `As per CLRA, ${this.selectedPlant.State} state has allowed only ${this.selectedPlant.LicenseMaxValue} gate pass without Labour License`
            })
          }
        });

        let activePersonSum = 0;
        this.licenseList.forEach((lic) => {
          activePersonSum = activePersonSum + lic.ActivePerson;
        })

        this.currentStep = 5;
        this.allStepDataObj = { ...this.allStepDataObj, ...this.objectAfterPlantSelect };
      }
      console.log(this.licenseList);
    });
  }

  public onLicenseSelection(data: any): void {
    console.log('data', data);
    this.allStepDataObj = { ...this.allStepDataObj, ...data };
    if (!this.allStepDataObj.isSubContractorFlowStarted) {
      this.currentStep = 6;
      return;
    }

    if (this.allStepDataObj.isSkipWCPolicy || this.allStepDataObj.labourCoverType === 'esic') {
      let reqObj = {
        Role_Code: this.useRoles[0].Role_Code,
        UserId: GLOBAL.USER_ID,
        IsActive: this.allStepDataObj.requestType === 103 ? 1 : 0,
        Contractor_Code: this.contractorCode,
        WorkOrder_Code: this.workOrderCode,
        SubWorkOrder_Code: this.selectedWorkOrderDetail.WorkOrder_Code,
        ActionType: 1,
        Search: null,
        SelectedField: null,
        Condition: null,
        FilterType: null
      }
      this.gatePassRequestProcessService.getWCPolicyNewList(this.allStepDataObj.WorkOrder_Code, reqObj).subscribe((wcPolicyData: any) => {
        this.wcPolicyList = wcPolicyData;
        this.allStepDataObj = { ...this.allStepDataObj, labourCoverType: data.labourCoverType };
      });
      this.currentStep = 7;
      return;
    }

    if (this.allStepDataObj.isSubContractorFlowStarted && this.allStepDataObj.labourCoverType === 'wcPolicy' && this.allStepDataObj.ECPolicy_Code) {
      this.currentStep = 8;
      return;
    }

    this.currentStep = 6;
  }

  public onAssociateCoverUnderSelection(data: any): void {
    console.log('data', data);
    if (this.allStepDataObj.contractorType === 'SUB_CONTRACTORS_MAN' && !this.allStepDataObj.isSubContractorFlowStarted && data.labourCoverType === 'esic') {
      this.contractorList = [];
      this.getContractors(0);
      this.currentStep = 2;
      this.allStepDataObj = { ...this.allStepDataObj, isSubContractorFlowStarted: true, labourCoverType: data.labourCoverType };
      return;
    }

    if (this.allStepDataObj.contractorType === 'SUB_CONTRACTORS_MAN' && data.labourCoverType === 'esic') {
      this.contractorList = [];
      this.getContractors(0);
      this.currentStep = 2;
      this.allStepDataObj = { ...this.allStepDataObj, labourCoverType: data.labourCoverType };
      return;
    }
    if (data.labourCoverType === 'wcPolicy') {
      let reqObj = {
        Role_Code: this.useRoles[0].Role_Code,
        UserId: GLOBAL.USER_ID,
        IsActive: this.allStepDataObj.requestType === 103 ? 1 : 0,
        Contractor_Code: this.allStepDataObj.ContractorVendor_Code,
        WorkOrder_Code: this.selectedWorkOrderDetail.WorkOrder_Code,
        SubWorkOrder_Code: this.allStepDataObj.SubWorkOrder_Code ? this.allStepDataObj.SubWorkOrder_Code : null,
        ActionType: 1,
        Search: null,
        SelectedField: null,
        Condition: null,
        FilterType: null
      }
      this.gatePassRequestProcessService.getWCPolicyNewList(this.allStepDataObj.WorkOrder_Code, reqObj).subscribe((wcPolicyData: any) => {
        this.wcPolicyList = wcPolicyData;
        this.currentStep = 7;
        this.allStepDataObj = { ...this.allStepDataObj, labourCoverType: data.labourCoverType };
      });
    } else {
      this.currentStep = 8;
      this.allStepDataObj = { ...this.allStepDataObj, labourCoverType: data.labourCoverType };
    }
  }

  public onWCPolicySelection(data: any): void {
    console.log('data', data);
    if (this.allStepDataObj.contractorType === 'SUB_CONTRACTORS_MAN' && !this.allStepDataObj.isSubContractorFlowStarted) {
      this.contractorList = [];
      this.getContractors(0);
      this.currentStep = 2;
      this.allStepDataObj = { ...this.allStepDataObj, ...data, isSubContractorFlowStarted: true };
      return;
    }

    this.currentStep = 8;
    this.allStepDataObj = { ...this.allStepDataObj, ...data };

  }

  public onGatepassJobTypeSelection(data: any): void {
    const requestObj = this.createGatepassListRequest({ ...this.allStepDataObj, jobType: data.jobType });
    this.gatePassRequestProcessService.getGatepassList(requestObj).subscribe((res: any[]) => {
      this.associateList = res;
      let minimumRemainingValue = [];
      const workOrderCapacityCheck = this.globalSettings.find(item => item.Code === 'MOD52PG195REQWOCAP');
      const licenseCapacityCheck = this.globalSettings.find(item => item.Code === 'MOD52PG195REQLICCAP');
      const wcPolicyCapacityCheck = this.globalSettings.find(item => item.Code === 'MOD52PG195REQECCAP');
      if (this.allStepDataObj.requestType == 103) {
        if (this.allStepDataObj && this.allStepDataObj.ECPolicy_Code && wcPolicyCapacityCheck && wcPolicyCapacityCheck.Value == '1') {
          let currentWCPolicy = this.wcPolicyList.find(wc => wc.ECPolicy_Code == this.allStepDataObj.ECPolicy_Code)
          if (currentWCPolicy) {
            minimumRemainingValue.push(currentWCPolicy.RemainingPerson)
          }
        }
        if (this.selectedWorkOrderDetail && this.selectedWorkOrderDetail.Remaining && workOrderCapacityCheck && workOrderCapacityCheck.Value == '1') {
          minimumRemainingValue.push(this.selectedWorkOrderDetail.Remaining)
        }
        if (this.allStepDataObj && this.allStepDataObj.Remaining && licenseCapacityCheck && licenseCapacityCheck.Value == '1') {
          minimumRemainingValue.push(this.allStepDataObj.Remaining)
        } else if (this.selectedPlant && licenseCapacityCheck && licenseCapacityCheck.Value == '1') {
          minimumRemainingValue.push(this.selectedPlant.LicenseMaxValue - this.selectedPlant.Active);
        }
      }
      const data = this.globalSettings.find(item => item.Code === 'MOD152PG198C100FV2');
      if (data) {
        minimumRemainingValue.push(data.Value);
      }
      this.associateCount = minimumRemainingValue.reduce((a, b) => Math.min(parseFloat(a), parseFloat(b)));
      this.allStepDataObj = { ...this.allStepDataObj, jobType: data.jobType };
      this.currentStep = 9;
    });
  }

  public onSubmit(data: any): void {
    if (this.allStepDataObj.requestType === 105) {
      this.onCancelRequestSubmit(data);
      return;
    }
    if (this.allStepDataObj.requestType === 104) {
      this.onRenewRequestSubmit(data);
      return;
    }
    if (this.allStepDataObj.requestType === 103) {
      this.onNewRequestSubmit(data);
      return;
    }
  }

  private onNewRequestSubmit(data: any): void {
    console.log('data', data);
    const objgatepass = this.prepareReqForActionSave({ ...this.allStepDataObj, labourCodes: data, actions: 'Approved' });
    this.gatePassRequestProcessService.insertGatePassRequest(objgatepass).subscribe((insertGetPassRequestResponse: any) => {
      this.logHelperService.logSuccess({
        message: 'Onboarding Request submitted successfully'
      });
      this.onClose({ refreshList: true });
    });
  }

  private onRenewRequestSubmit(data: any): void {
    console.log('data', data);
    const objgatepass = this.prepareReqForActionSave({ ...this.allStepDataObj, labourCodes: data, actions: 'Approved' });
    this.gatePassRequestProcessService.insertGatePassRequestRenew(objgatepass).subscribe((insertGetPassRequestResponse: any) => {
      this.logHelperService.logSuccess({
        message: 'Onboarding Request submitted successfully'
      });
      this.onClose({ refreshList: true });
    });
  }

  private onCancelRequestSubmit(data: any): void {
    console.log('data', data);
    const objgatepass = this.prepareReqForActionSave({ ...this.allStepDataObj, labourCodes: data, actions: 'Approved' });
    this.gatePassRequestProcessService.insertGatePassRequestCancel(objgatepass)
      .pipe(
        switchMap(response => {
          const cancelObj = this.prepareReqForCancelActionSave({ ...this.allStepDataObj, labourCodes: data });
          return this.gatePassRequestProcessService.insertGatePassRequestBulkCancel(cancelObj);
        })
      )
      .subscribe((insertGetPassRequestResponse: any) => {
        // this.gatePassRequestProcessService.insertGatePassRequest(objgatepass).subscribe((insertGetPassRequestResponse: any) => {
        this.logHelperService.logSuccess({
          message: 'Onboarding Request submitted successfully'
        });
        this.onClose({ refreshList: true });
      });
  }

  public onPrevAction(step: number): void {
    this.currentStep = step;
  }

  public onAssociateSelectionPrevAction(): void {
    if (this.allStepDataObj.requestType === 105) {
      this.currentStep = 3;
    } else {
      this.currentStep = 8;
    }
  }

  public onWorkOrderSelectionPrevAction(): void {
    this.selectedWorkOrderDetail = this.oldSelectedWorkOrderDetails;
    if (this.allStepDataObj.contractorType === 'SUB_CONTRACTORS_MAN' && !!this.allStepDataObj.isSubContractorFlowStarted) {
      this.currentStep = 2;
      return;
    }
    if (this.isContractor) {
      this.currentStep = 1;
    } else {
      this.currentStep = 2;
    }
  }

  public onWCPolicyPrevAction(step: number): void {
    if (this.allStepDataObj.isSubContractorFlowStarted && !!this.allStepDataObj.SubWorkOrder_Code) {
      this.currentStep = 5;
      return;
    }
    if (this.allStepDataObj.isSubContractorFlowStarted) {
      this.currentStep = 6;
      return;
    }
    if (this.allStepDataObj.isSubContractorFlowStarted && this.allStepDataObj.isSkipWCPolicy && this.allStepDataObj.labourCoverType === 'wcPolicy') {
      this.currentStep = 7;
      return;
    }
    if (this.allStepDataObj.labourCoverType === 'wcPolicy') {
      this.currentStep = 6;
      return;
    }
    this.currentStep = 6;
  }

  public onGatepassJobTypePrevAction(step: number): void {
    if (this.allStepDataObj.isSubContractorFlowStarted && this.allStepDataObj.isSkipWCPolicy && this.allStepDataObj.labourCoverType === 'wcPolicy') {
      this.currentStep = 7;
      return;
    }
    if (this.allStepDataObj.isSubContractorFlowStarted && this.allStepDataObj.labourCoverType === 'wcPolicy') {
      this.currentStep = 5;
      return;
    }
    if (this.allStepDataObj.isSubContractorFlowStarted && this.allStepDataObj.labourCoverType === 'esic') {
      this.currentStep = 6;
      return;
    }
    if (this.allStepDataObj.labourCoverType === 'wcPolicy') {
      this.currentStep = 7;
      return;
    }
    this.currentStep = 6;
  }

  public onClose(data?: any): void {
    this.handleClose.emit(data);
  }

  private getAlertMessageFromGlobalSource(): void {
    this.sharedService.alertMessages$.subscribe((alertMessages: any[]) => {
      this.alertMessages = alertMessages;
    });
  }
  private checkGlobalSettingsForVisibility(): void {
    this.sharedService.globalSettingsDataSource$.subscribe((settings: any) => {
      if (settings && settings.Data && settings.Data.Table) {
        this.globalSettings = settings.Data.Table;
        this.checkSettingForFirstStep(settings.Data.Table);
        // this.checkSettingForAssociateCount(settings.Data.Table);
        this.checkSettingForResignationInput(settings.Data.Table);
      }
    });
  }

  private checkSettingForFirstStep(settings: any[]): void {
    const data = settings.find(item => item.Code === 'MOD52PG195REQSUBCON');
    if (data) {
      let value = data.Value;
      if (this.userSiteCode === data.Plant_Code) {
        value = data.Value;
      }
      if (value === '1') {
        this.currentStep = 1;
        this.getContractors();
      } else if (this.isContractor) {
        this.allStepDataObj = { ...this.allStepDataObj, contractorType: ContractorType.Contractor };
        // this.currentStep = 3;
        this.isDisableWorkOrderPrevBtn = true;
        this.getContractors(1, true);
        // this.onContractorSelection({ ContractorVendor_Code: null, Company: '' })
      } else {
        this.allStepDataObj = { ...this.allStepDataObj, contractorType: ContractorType.Contractor };
        this.currentStep = 2;
        this.isDisableContractorPrevBtn = true;
        this.getContractors();
      }
    }
  }

  // private checkSettingForAssociateCount(settings: any[]): void {
  //   const data = settings.find(item => item.Code === 'MOD152PG198C100FV2');
  //   if (data) {
  //     this.associateCount = data.Value;
  //   }
  // }

  private checkSettingForResignationInput(settings: any[]): void {
    const data = settings.find(item => item.Code === 'MOD152PG198C100FV1');
    if (data && this.allStepDataObj.requestType === 105) {
      this.isResingationInput = !!(+data.Value);
    }
  }

  private getContractors(isMain = 1, isCallContractorSelection: boolean = false): void {
    if (this.useRoles) {
      const reuqestObj = this.createContractorListRequest(this.allStepDataObj, isMain)
      this.gatePassRequestProcessService.getContractors(reuqestObj)
        .pipe(takeUntil(this.destroy))
        .subscribe(res => {
          this.contractorList = res;
          if (res && res.length > 0 && isCallContractorSelection) {
            this.onContractorSelection({ ContractorVendor_Code: this.contractorList[0].ContractorVendor_Code, Company: this.contractorList[0].Company })
          }
        })
    }
  }

  private getCurrentUserRoles(): any {
    return JSON.parse(localStorage.getItem('Role'));
  }

  private createContractorListRequest({ ContractorVendor_Code = null, WorkOrder_Code = null, contractorType }: GatepassRequestProcess, isMain): any {
    this.contractorCode = ContractorVendor_Code;
    return {
      search: null,
      sDate: null,
      eDate: null,
      selectedField: 'Company,ContractorVendor_Code,EmailID,ShortCode,PANNo,IsContactor,Status',
      isMain,
      Contractor_Code: isMain === 1 ? null : ContractorVendor_Code,
      ExcludeContractor_Code: isMain === 1 ? null : ContractorVendor_Code,
      workOrderCode: WorkOrder_Code,
      Plant_Code: null,
      UserID: GLOBAL.USER_ID,
      RequirType: null
    }
  }

  private createWorkOrderListRequest({ ContractorVendor_Code, WorkOrder_Code = null, SubContractor_Code, Role_Code }: GatepassRequestProcess): any {
    this.workOrderCode = this.selectedWorkOrderDetail ? this.selectedWorkOrderDetail.WorkOrder_Code : null
    return {
      Role_Code,
      UserId: GLOBAL.USER_ID,
      ContractorVendor_Code,
      SubContractorVendor_Code: SubContractor_Code,
      ActionType: 1,
      WorkOrder_Code: this.selectedWorkOrderDetail ? this.selectedWorkOrderDetail.WorkOrder_Code : null,
      Plant_Code: null,
      Search: null,
      selectedField: null
    }
  }

  private createPlantListRequest(workOrder_Code) {
    return {
      "search": null,
      "selectedField": null,
      "WorkOrder": this.allStepDataObj.isSubContractorFlowStarted ? this.workOrderCode : workOrder_Code,
      "SubWorkOrder": this.allStepDataObj.isSubContractorFlowStarted ? workOrder_Code : null,
      "UserID": GLOBAL.USER_ID,
      "ActionType": 0,
      "RequireType": 0,
    }
  }

  private createLicenseListRequest({ ContractorVendor_Code, WorkOrder_Code, SubContractor_Code, Plant_Code, SubWorkOrder_Code = null }: GatepassRequestProcess): any {
    return {
      Role_Code: this.useRoles[0].Role_Code,
      UserId: localStorage.getItem('UserID'),
      IsActive: this.allStepDataObj.requestType === 103 ? 1 : 0,
      Contractor_Code: ContractorVendor_Code,
      WorkOrder_Code: this.allStepDataObj.isSubContractorFlowStarted ? this.workOrderCode : WorkOrder_Code,
      // SubContractor_Code,
      // Plant_Code,
      SubWorkOrder_Code,
      ActionType: 1
    }
  }

  private createGatepassListRequest(data: GatepassRequestProcess): any {
    const { requestType, labourCoverType, ContractorVendor_Code, WorkOrder_Code, SubContractor_Code, SubWorkOrder_Code,
      License_Code, NewLicense_Code, SubLicense_Code, SubNewLicense_Code, ECPolicy_Code, NewECPolicy_Code } = data;
    return {
      labourcovered: labourCoverType,
      Contractor_Code: ContractorVendor_Code,
      WorkOrder_Code: WorkOrder_Code,
      SubContractorcode: SubContractor_Code,
      SubWorkorder: SubWorkOrder_Code,
      // License_Code,
      NewLicense_Code,
      SubLicense_Code: License_Code,
      SubNewLicense_Code,
      ECPolicy_Code,
      NewECPolicy_Code,
      ESICWageCriteria: null,
      RequestType_ShortCode: this.getRequestType(requestType)
      // --'RT103'-New / 'RT104' - Renewal /  'RT105' - Cancelation
    }
  }

  private prepareReqForActionSave(data: GatepassRequestProcess): any {
    const labourCodes = data.labourCodes.map(item => item.Labour_Code);
    const objgatepass = {
      Job: data.jobType,
      Labour_codes: labourCodes,
      Plant_code: null,
      Reqtype: data.requestType,
      RoleCode: this.useRoles[0].Role_Code,
      State_Code: 111,
      UserEmail: GLOBAL.USER.EMAIL_ID,
      companypolicyCode: null,
      contractorcode: data.ContractorVendor_Code,
      isLabour: null,
      labourcovered: data.labourCoverType,
      licensecode: data.License_Code,
      subContractorcode: null,
      subPlant_Code: null,
      sublincensecode: null,
      subworkoderordercode: null,
      wcpolicycode: data.ECPolicy_Code,
      workoderordercode: data.WorkOrder_Code,
      UserId: localStorage.getItem('UserID')
    };

    return objgatepass;
  }

  private prepareReqForCancelActionSave(data: GatepassRequestProcess): any {
    return data.labourCodes.map(item => ({
      GatePassNo: item.GatePassNo,
      RequirType: 1,
      ActionType: 1,
      IsLabour: true,
      CloseDate: moment(item.resignDate).format(this.sharedService.datePickerSettings.requestFormat),
      UserId: localStorage.getItem('UserID')
    }));
  }

  private getRequestType(type: number): string {
    const requestTypeObj = {
      103: 'RT103',
      104: 'RT104',
      105: 'RT105',
    };

    return requestTypeObj[type];
  }
}