import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-ag-action-cell-renderer',
  templateUrl: './ag-action-cell-renderer.component.html',
  styleUrls: ['./ag-action-cell-renderer.component.css']
})
export class AgActionCellRendererComponent implements OnInit, ICellRendererAngularComp {
  public params: any;

  public isEdit = true;
  public isDelete = true;
  public isUpdate = false;
  public isRightArrow = false;
  public isPrint = false;
  public isEmail = false;

  constructor() { }

  ngOnInit() {
  }

  agInit(params: any): void {
    if (!params.data.parent) {
      this.params = params;
    }
    
    if (params.isEdit === false) {
      this.isEdit = false;
    }
    if (params.isDelete === false) {
      this.isDelete = false;
    }
    this.isUpdate = params.isUpdate;
    this.isRightArrow = params.isRightArrow;
    this.isPrint = params.isPrintButton;
    this.isEmail = params.isEmail;
  }

  refresh(): boolean {
    return false;
  }

  onEdit() {
    this.params.context.componentParent.onEdit(this.params.data);
  }
  onPrint(){
    this.params.context.componentParent.onPrint(this.params.data);
  }
  onEmail(){
    this.params.context.componentParent.onEmail(this.params.data);
  }

  onDelete() {
    this.params.context.componentParent.onDelete(this.params.data, null, this.params.isParent);
  }

  onUpdate() {
    this.params.context.componentParent.onUpdate(this.params.data, null, this.params.isParent);
  }

  onRightArrowClick() {
    this.params.context.componentParent.onRightArrowClick(this.params.data, null, this.params.isParent);
  }

}
