import { CommonModule } from '@angular/common';
// import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AgGridModule } from 'ag-grid-angular';
import { OwlDateTimeModule, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { ImageCropperModule } from 'ngx-image-cropper';
import { OwlMomentDateTimeModule } from 'ng-pick-datetime-moment';
import { CookieService } from 'ngx-cookie-service';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { GestureConfig, OwlFormFieldModule, OwlInputMaskModule, OwlSelectModule, OwlTooltipModule } from 'owl-ng';
import {
  AccordionComponent,
  AgActionCellRendererComponent,
  AgNumberCountComponent,
  AutocompleteTreeviewComponent,
  FileUploadComponent,
  FileUploadService,
  GridWrapperComponent,
  SelectStepContentComponent,
  SelectStepsComponent,
  TreeviewModule,
  ValidationChecklistComponent,
  WizardWrapperComponent,
  NgxSidebarComponent,
  NgxSidebarService,
  Ng4LoadingSpinnerService,
  AdvanceFilterComponent,
  PivotTableComponent,
  AgActionDeleteCellRendererComponent,
  AgActionCellEditRendererComponent,
  AgActionCheckboxRendererComponent,
  CustomDateRangeFilterComponent,

} from './components';
import {
  AgSelectActionCellRendererComponent
} from './components/grid-wrapper/helper/ag-select-action-cell-renderer/ag-select-action-cell-renderer.component';
import { GetTemplateDirective } from './directives';
import { CustomMaskDirective } from './directives/custom-mask.directive';

export let options: Partial<IConfig> | (() => Partial<IConfig>);
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { SidebarModule } from 'ng-sidebar';
import { AppPasswordDirective } from './directives/app-password.directive';
import { EqualValidator } from './directives/equal-validator.directive';
import { LogHelperService } from './services/log-helper.service';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { SelectionWrapperService } from './services/selection-wrapper.service';
import { SelectStepsFilterWrapperComponent } from './components/select-steps-filter-wrapper/select-steps-filter-wrapper.component';
import { SelectStepsFilterService } from './services/select-steps-filter.service';
import { SelectStepsService } from './components/select-steps/select-step.service';
import {
  SelectStepsSequenceFilterWrapperComponent
} from './components/select-steps-sequence-filter-wrapper -/select-steps-sequence-filter-wrapper.component';
import {
  AgFilterCellRenderComponent
} from './components/select-steps-filter-wrapper/ag-filter-cell-render/ag-filter-cell-render.component';
import {
  AdvanceFilterDynamicComponent
} from './components/select-steps-filter-wrapper/advance-filter-dynamic/advance-filter-dynamic.component';
import {
  AdvanceFilterTypeConditionComponent
} from './components/select-steps-filter-wrapper/advance-filter-type-condition/advance-filter-type-condition.component';
import { CommonGridWrapperComponent } from './components/common-grid-wrapper/common-grid-wrapper.component';
import { SharedService } from './services';
import { OnlyDecimalDirective } from './directives/only-decimal.directive';
import { OnlyNumbeDirective } from './directives/only-number.directive';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from '../http.interceptor';
import { DownloadImportSampleComponent } from './components/download-import-sample/download-import-sample.component';
import { ListFilterPipe } from './pipes/list-filter.pipe';
import { AppQrCodeComponent } from './components/app-qr-code/app-qr-code.component';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxPrintModule } from 'ngx-print';
import { ReportsGridWrapperComponent } from '../reports/reports-grid-wrapper/reports-grid-wrapper.component';
// tslint:disable-next-line: max-line-length
import { AgActionAttendanceCheckboxRenderComponent } from './components/grid-wrapper/helper/ag-action-attendance-checkbox-render/ag-action-attendance-checkbox-render.component';
// tslint:disable-next-line: max-line-length
import { AgActionCellDocRendererComponent } from './components/grid-wrapper/helper/ag-action-cell-doc-render/ag-action-cell-doc-render.component';
// tslint:disable-next-line:max-line-length
import { AgActionCellLockRendererComponent } from './components/grid-wrapper/helper/ag-action-cell-lock-renderer/ag-action-cell-lock-renderer.component';
// tslint:disable-next-line:max-line-length
import { AgActionCellActiveRendererComponent } from './components/grid-wrapper/helper/ag-action-cell-active-renderer/ag-action-cell-active-renderer.component';
// tslint:disable-next-line:max-line-length
import { AgActionCellPermissionRendererComponent } from './components/grid-wrapper/helper/ag-action-cell-permission-renderer/ag-action-cell-permission-renderer.component';
import { WorkOrderGridWrapperComponent } from '../contractor/work-order-grid-wrapper/work-order-grid-wrapper.component';
import { FaceTrainImageFileComponent } from '../face-recognition/face-file-upload/face-train-file.component';
import { FaceRecognizatonComponent } from '../face-recognition/face-recognization/face-recognization.component';
import { FaceTrainCameraComponent } from '../face-recognition/face-train-cam/face-train-cam.component';
import { FaceRecognitionUIComponent } from '../face-recognition/faceRecognitionUI.component';
import { CustomRoleBasedContentDirective } from './directives/custom-role-based.directive';
import { MonthNamePipe } from './pipes/month-name.pipe';
import { FormatFileSize } from './pipes/format-file-size';
import { EditEmailComponent } from './components/edit-email/edit-email.component';

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
// const dateFormat = 'DD-MM-YYYY hh:mm';// old KH 09-05-2020
const dateFormat = 'DD-MM-YYYY'; // new KH 09-05-2020
export const MY_MOMENT_FORMATS = {
  parseInput: dateFormat,
  fullPickerInput: dateFormat,
  datePickerInput: dateFormat,
  timePickerInput: dateFormat,
  monthYearLabel: dateFormat,
  dateA11yLabel: dateFormat,
  monthYearA11yLabel: dateFormat,
};
// import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/compiler/src/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    // Ng4LoadingSpinnerModule.forRoot(),
    AgGridModule.withComponents([]),
    TreeviewModule.forRoot(),
    PerfectScrollbarModule,
    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    // BrowserAnimationsModule,
    OwlFormFieldModule, OwlSelectModule,
    OwlTooltipModule,
    OwlInputMaskModule,
    NgSelectModule,
    NgxMaskModule.forRoot(options),
    SidebarModule.forRoot(),
    SweetAlert2Module.forRoot(),
    ImageCropperModule,
    QRCodeModule,
    NgxPrintModule,
  ],
  declarations: [
    GridWrapperComponent,
    ReportsGridWrapperComponent,
    AgNumberCountComponent,
    AgActionCellRendererComponent,
    AgActionCellLockRendererComponent,
    AgActionCellPermissionRendererComponent,
    AgActionCellActiveRendererComponent,
    AgActionCheckboxRendererComponent,
    AgActionAttendanceCheckboxRenderComponent,
    SelectStepsFilterWrapperComponent,
    SelectStepsSequenceFilterWrapperComponent,
    AccordionComponent,
    WizardWrapperComponent,
    ValidationChecklistComponent,
    AutocompleteTreeviewComponent,
    FileUploadComponent,
    SelectStepsComponent,
    SelectStepContentComponent,
    GetTemplateDirective,
    CustomMaskDirective,
    AgSelectActionCellRendererComponent,
    NgxSidebarComponent,
    AdvanceFilterComponent,
    AppPasswordDirective,
    EqualValidator,
    AdvanceFilterComponent,
    SelectStepsFilterWrapperComponent,
    AgFilterCellRenderComponent,
    PivotTableComponent,
    AdvanceFilterDynamicComponent,
    AdvanceFilterTypeConditionComponent,
    AgActionDeleteCellRendererComponent,
    CommonGridWrapperComponent,
    AgActionCellEditRendererComponent,
    OnlyDecimalDirective,
    OnlyNumbeDirective,
    DownloadImportSampleComponent,
    CustomDateRangeFilterComponent,
    ListFilterPipe,
    AppQrCodeComponent,
    AgActionCellDocRendererComponent,
    WorkOrderGridWrapperComponent,
    FaceRecognitionUIComponent,
    FaceRecognizatonComponent,
    FaceTrainCameraComponent,
    FaceTrainImageFileComponent,
    CustomRoleBasedContentDirective,
    MonthNamePipe,
    FormatFileSize,
    EditEmailComponent,
  ],
  entryComponents: [
    AgNumberCountComponent,
    AgFilterCellRenderComponent,
    SelectStepContentComponent,
    AgActionCellRendererComponent,
    AgActionCellLockRendererComponent,
    AgActionCellPermissionRendererComponent,
    AgActionCellActiveRendererComponent,
    AgActionCheckboxRendererComponent,
    AgActionAttendanceCheckboxRenderComponent,
    AgSelectActionCellRendererComponent,
    AdvanceFilterDynamicComponent,
    AdvanceFilterTypeConditionComponent,
    AgActionDeleteCellRendererComponent,
    AgActionCellEditRendererComponent,
    CustomDateRangeFilterComponent,
    AppQrCodeComponent,
    AgActionCellDocRendererComponent,
    WorkOrderGridWrapperComponent,
    FaceRecognitionUIComponent,
    FaceRecognizatonComponent,
    FaceTrainCameraComponent,
    FaceTrainImageFileComponent,
    EditEmailComponent
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    GridWrapperComponent,
    ReportsGridWrapperComponent,
    AgNumberCountComponent,
    AgFilterCellRenderComponent,
    AgActionCellRendererComponent,
    AgActionCellLockRendererComponent,
    AgActionCellPermissionRendererComponent,
    AgActionCellActiveRendererComponent,
    AgActionCheckboxRendererComponent,
    AgActionAttendanceCheckboxRenderComponent,
    SelectStepsFilterWrapperComponent,
    SelectStepsSequenceFilterWrapperComponent,
    AgSelectActionCellRendererComponent,
    AdvanceFilterTypeConditionComponent,
    AccordionComponent,
    SelectStepsComponent,
    WizardWrapperComponent,
    ValidationChecklistComponent,
    AutocompleteTreeviewComponent,
    FileUploadComponent,
    NgbModule,
    AgGridModule,
    TreeviewModule,
    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    OwlFormFieldModule, OwlSelectModule,
    OwlTooltipModule,
    OwlInputMaskModule,
    // AngularMultiSelectModule,
    NgSelectModule,
    GetTemplateDirective,
    NgSelectModule,
    NgxMaskModule,
    CustomMaskDirective,
    SidebarModule,
    NgxSidebarComponent,
    AppPasswordDirective,
    EqualValidator,
    AdvanceFilterComponent,
    AdvanceFilterDynamicComponent,
    PivotTableComponent,
    AgActionDeleteCellRendererComponent,
    CommonGridWrapperComponent,
    ImageCropperModule,
    AgActionCellEditRendererComponent,
    OnlyDecimalDirective,
    OnlyNumbeDirective,
    DownloadImportSampleComponent,
    CustomDateRangeFilterComponent,
    ListFilterPipe,
    AppQrCodeComponent,
    QRCodeModule,
    NgxPrintModule,
    AgActionCellDocRendererComponent,
    WorkOrderGridWrapperComponent,
    FaceRecognitionUIComponent,
    FaceRecognizatonComponent,
    FaceTrainCameraComponent,
    FaceTrainImageFileComponent,
    CustomRoleBasedContentDirective,
    MonthNamePipe,
    FormatFileSize,
    EditEmailComponent
  ],
  providers: [
    CookieService,
    // SharedService,
    SelectStepsService,
    SelectStepsFilterService,
    SelectionWrapperService,
    FileUploadService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-IN' },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    NgxSidebarService,
    Ng4LoadingSpinnerService,
    LogHelperService,
    MonthNamePipe
  ],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        CookieService,
        SelectStepsService,
        SelectStepsFilterService,
        SelectionWrapperService,
        FileUploadService,
        { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
        {
          provide: PERFECT_SCROLLBAR_CONFIG,
          useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
        { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-IN' },
        { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
        NgxSidebarService,
        Ng4LoadingSpinnerService,
        LogHelperService
      ]
    };
  }
}
