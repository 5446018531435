import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from './shared.service';
import { LoginService } from 'src/app/login/login.service';
import { BehaviorSubject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

const browserWindow = window || {};
const browserWindowEnv = browserWindow['__env'] || {};

@Injectable({
  providedIn: 'root'
})
export class IdleTimeoutService {

  private idleTimeout: any;
  private readonly idleTime = browserWindowEnv.idelTime * 60 * 1000;
  private twoMinuteRemaining;
  public showToastr : BehaviorSubject<boolean> = new BehaviorSubject(false);
  public twoMinTimer;

  constructor(private ngZone: NgZone, private router: Router, private sharedService: SharedService, private loginService: LoginService,private modalService: NgbModal) { }

  resetTimer(): void {
    this.showToastr.next(false);
    this.twoMinuteRemaining = this.idleTime - ( 2 * 60 * 1000);
    clearTimeout(this.twoMinTimer);
    this.stopTimer();
    this.startTimer();
  }

  private startTimer(): void {

    this.twoMinTimer = setTimeout(() => {
      console.log("2 min")
      this.showToastr.next(true);
    }, this.twoMinuteRemaining);
    
    this.idleTimeout = setTimeout(() => {
      // Perform logout or any other action here
      this.ngZone.run(() => {
        console.log('User is logged out due to inactivity.');
        this.showToastr.next(false);
        this.logout();
      });
    }, this.idleTime);
  }

  private stopTimer(): void {
    clearTimeout(this.idleTimeout);
  }

  logout() {
    this.modalService.dismissAll();
    this.sharedService.deleteCookie('token');
    this.sharedService.deleteCookie('OceanSystem');
    localStorage.clear();
    this.router.navigate(['login']);
    this.stopTimer();
    this.loginService.updateOnAuthChange(false);
  }
}
