import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
declare var $: any;
import { Observable, of, Subject } from 'rxjs';
import * as moment from 'moment';
import { map, switchMap } from 'rxjs/operators';
import { GLOBAL, PLANT_UNIT_DEP_REQUEST } from '../app.globals';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class ImportExcelService {

  private payrollYearIdActionSubject = new Subject<number>();
  private payrollYearIdSelectionAction$ = this.payrollYearIdActionSubject.asObservable();

  public payrollYears$: Observable<any[]> = this.getPayrollYears({});
  public payrollMonths$: Observable<any[]> = this.payrollYearIdSelectionAction$.pipe(switchMap(id => this.getPayrollMonths(id)));

  constructor(
    private httpClient: HttpClient
  ) { }

  public setPayrollYearChangeAction(id: number): void {
    this.payrollYearIdActionSubject.next(id);
  }

  getMasterlistData() {

    // const model = {
    //   ShortCode: '',
    //   Search: '',
    //   PageCode: '',
    // };
    // return this.httpClient.post(`${GLOBAL.APIS.MASTER_DATA.GET_MASTERITEMLIST}`, model);
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(GLOBAL.APIS.MASTER_DATA.GET_MASTERITEMLIST + '?Search=&ShortCode=&PageCode=');
  }


  /**
   * getSkillType
   * @author Sonal Prajapati
   */
  getSkillType() {
    const model = {
      Search: '',
      Plant_Code: '',
      ParentSkill_Code: '',
      Trade_Code: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_SKILL_TYPES}`, model);
  }
  // tslint:disable-next-line: prefer-template
  // return this.http.get(GLOBAL.APIS.LABOUR_MANAGEMENT.GET_SKILL_TYPES
  // + '?Search=&PlantCode=&ParentSkillCode=&TradeCode=');
  // }

  /**
   * get All trades
   * @author Sonal Prajapati
   */
  getAllTrade() {
    const model = {
      Search: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_ALL_TRADE}`, model);
  }
  // tslint:disable-next-line: prefer-template
  // return this.http.get(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_ALL_TRADE}?Search=`);
  // }
  getAllSiteShiftGroup() {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_ALL_SiteShiftGroup}`);
  }
  public formatToServerDate(date) {
    let a = moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD');
    if (a === 'Invalid date') {
      a = null;
    }
    return a;
  }


  // Import Labour HR data
  public importDataHr(data): Observable<any> {
    // const reqData = this.prepareReqData(data, userEmail);
    httpOptions['body'] = JSON.stringify(data);
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.POST_IMPORT_LABOUR_HR}`,
      httpOptions
    );
  }
  // Import Labour HR data
  public importDataSubLabourHr(data): Observable<any> {
    // const reqData = this.prepareReqData(data, userEmail);
    httpOptions['body'] = JSON.stringify(data);
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.IMPORT_SUBCONTRACTOR_LABOUR_HR}`,
      httpOptions
    );
  }
  public getPincodeDetails(pincodeList): Observable<any> {
    // const model = {
    //   Search: '',
    // };
    // return this.httpClient.post(`${GLOBAL.APIS.MASTER_DATA.GET_PINCODE_DETAILS}`, model);
    const pincodes = pincodeList.join(',');
    return this.httpClient.get(`${GLOBAL.APIS.MASTER_DATA.GET_PINCODE_DETAILS_DATA}?zipcode=${pincodes}`);
    // }
  }
  // const pincodes = pincodeList.join(',');
  // return this.http.get(`${GLOBAL.APIS.MASTER_DATA.GET_PINCODE_DETAILS}?zipcode=${pincodes}`);
  // }

  public getDepartmentPlantUnitSectionTree(): Observable<any> {
    const queryString = $.param({
      userId: localStorage.getItem('UserID'),
    });
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DEPARTMENT_PLANT_UNIT_SECTION_TREE_New}?${queryString}`;
    return this.httpClient.get(url).pipe(map((response: any) => response));
  }

  public getDepartmentPlantUnitSectionData(): Observable<any> {
    PLANT_UNIT_DEP_REQUEST.Filter.UserId = localStorage.getItem('UserID');
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DEPARTMENT_PLANT_UNIT_SECTION_DATA}`;
    return this.httpClient.post(url, PLANT_UNIT_DEP_REQUEST);
  }

  public validateWorkOrderContractor(data): Observable<any> {
    httpOptions['body'] = JSON.stringify(data);
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.VALIDATE_WORK_ORDER_CONTACTOR}`,
      httpOptions
    );
  }
  public subvalidateWorkOrderContractor(data): Observable<any> {
    httpOptions['body'] = JSON.stringify(data);
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.VALIDATE_SubWORK_ORDER_CONTACTOR}`,
      httpOptions
    );
  }

  public sublicencecodeContractor(data): Observable<any> {
    httpOptions['body'] = JSON.stringify(data);
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.VALIDATE_SubLicence_CONTACTOR}`,
      httpOptions
    );
  }

  public subecpolicycodeContractor(data): Observable<any> {
    httpOptions['body'] = JSON.stringify(data);
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.VALIDATE_SubECpolicy_CONTACTOR}`,
      httpOptions
    );
  }
  public validateLicenceContractor(data): Observable<any> {
    httpOptions['body'] = JSON.stringify(data);
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.VALIDATE_LICENCE_CONTACTOR}`,
      httpOptions
    );
  }

  public validateECPolicyContractor(data): Observable<any> {
    httpOptions['body'] = JSON.stringify(data);
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.VALIDATE_EC_POLICY_CONTACTOR}`,
      httpOptions
    );
  }
  public subvalidateSubContractor(list): Observable<any> {
    httpOptions['body'] = JSON.stringify(list);
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.VALIDATE_SUBCONTRACTOR_CONTACTOR}`,
      httpOptions
    );
  }

  public validateULC(list): Observable<any> {
    httpOptions['body'] = JSON.stringify(list);
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.VALIDATE_ULC}`,
      httpOptions
    );
  }

  public validateGatePass(list): Observable<any> {
    httpOptions['body'] = JSON.stringify(list);
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.VALIDATE_GATE_PASS}`,
      httpOptions
    );
  }

  public validateGatePassULC(data): Observable<any> {
    httpOptions['body'] = JSON.stringify(data);
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.VALIDATE_GATE_PASS_ULC}`,
      httpOptions
    );
  }

  public checkDuplicateAdharNumber(data): Observable<any> {
    httpOptions['body'] = JSON.stringify(data);
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.VALIDATE_ADHAR_CARD_NUMBER}`,
      httpOptions
    );
  }
  public checkULcAgainstgatepassno(data): Observable<any> {
    httpOptions['body'] = JSON.stringify(data);
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.VALIDATE_GATE_PASS_ULC_Exsit}`,
      httpOptions
    );
  }
  public validateGatePassNoULCAndIdentity(data): Observable<any> {
    httpOptions['body'] = JSON.stringify(data);
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.VALIDATE_GATE_PASS_ULC_IDENTITY}`,
      httpOptions
    );
  }
  public validateGatePassNoULCForNew(data): Observable<any> {
    httpOptions['body'] = JSON.stringify(data);
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.VALIDATE_GATE_PASS_ULC_NEW}`,
      httpOptions
    );
  }
  // Import Manual Attendance Data
  public importManualAttendance(data): Observable<any> {
    httpOptions['body'] = JSON.stringify(data);
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.IMPORT_MANUAL_ATTENDANCE_LIST}`,
      httpOptions
    );
  }

  public uploadBulkEnrollmentDelete(data: any): Observable<any> {
    return this.httpClient.post(`${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.CLPMS_SP_ATT_EXCEL_BulkDeleteEnrollment}`, data);
  }

  public savePayrollHandEntry(data: any[]): Observable<any> {
    return this.httpClient.post(`${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.CLPMS_SP_PAYROLL_Labour_Insert_Update_HandEntryOverride}`, data);
  }

  public getPayrollYears(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.REPORTS.CLPMS_SP_FinancialYear}`;
    return this.httpClient.post<any[]>(url, data);
  }

  public getPayrollMonths(id: number): Observable<any[]> {
    const requestObj = {
      FinancialYear_Code: id,
      Plant_Code: null,
      selectedField: null
    }
    const url = `${GLOBAL.APIS.REPORTS.CLPMS_SP_PayrollMonth_By_Year}`;
    return this.httpClient.post<any[]>(url, requestObj);
  }

  public getUserSiteCode(data: any): Observable<any> {
    return this.httpClient.post(`${GLOBAL.APIS.COMMON.COMMON_SP_UserPlantAccess}`, data);
  }

  public DownloadCLPMSsampleExel(payload): Observable<any> {
    const url = `${GLOBAL.APIS.COMMON.CLPMS_LINK_SAMPLE_EXEL}`

    const paylaod = {
      "Plant_Code": null,
      "SiteMIL_Code": 950,
      "Short_Code": "LBRIMPEXL001",
      "ISActiveStatus": 1,
      "RequireType": 1,
      "ActionType": 0,
      "UserID": GLOBAL.USER_ID
    }

    return this.httpClient.post(url, paylaod)
  }

  public globalSettingCode(code: string) {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_GLOBAL_SETTING}?Code=${code}`

    return this.httpClient.get(url)
  }

  public getCommonSpUserPlantAcces() {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_COMMON_SP_USERPLANTACCES}`

    let payload = {
      "UserID": GLOBAL.USER_ID,
      "RequireType": 1,
      "SiteMIL_Code": null,
      "ActionType": null,
    }

    return this.httpClient.post<any[]>(url, payload)
  }

  public getStatesList(plantCode: any): Observable<any[]> {
    const req = {
      // "Search": "",
      // "Plant_Code": plantCode
      Search: "",
      selectedField: null,
      State_Code: "",
      Plant_Code: plantCode,
      RequireType: 0,
      ActionType: 0,
      UserId: 100
    }
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_STATE_DATA_SP}`;
    console.log(url);
    return this.httpClient.post<any[]>(url, req).pipe(
      map((response: any) => response));
  }
}
