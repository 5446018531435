import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { LicencesService } from './licences.service';
import { AgNumberCountComponent, AgActionCellRendererComponent } from '../../core/components';
import { GLOBAL } from 'src/app/app.globals';

@Component({
  selector: 'app-licences',
  templateUrl: './licences.component.html',
  styleUrls: ['./licences.component.css']
})
export class LicencesComponent implements OnInit {
  // tslint:disable-next-line: variable-name
  ForContractorLogin = true;
  public contractorType: number;
  public columnDefs: any[];
  public rowData: [];
  constructor(private licenceService: LicencesService,
    private router: Router) {

    this.columnDefs = [
      {
        headerName: '#', field: '', width: 50, cellRendererFramework: AgNumberCountComponent,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Code', field: 'License_Code', width: 100,
        filterParams: { applyButton: true, clearButton: true }, filter: true, sortable: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center',
      },
      {
        headerName: 'Contactor', field: 'Company', width: 300,
        filterParams: { applyButton: true, clearButton: true },  filter: true, sortable: true,
        cellStyle: { textAlign: 'left' }, headerClass: 'align-center',
        cellRenderer: function (params) { return '<span class="hover-shadow">' + params.value + '&nbsp;&nbsp; <i class="fa fa-clipboard" [cdkCopyToClipboard]=' + params.value + '></i> </span>' },
        tooltip: (params) => params.value,
      },
      {
        headerName: 'Plant', field: 'PlantName', width: 150,
        filterParams: { applyButton: true, clearButton: true }, filter: true, sortable: true,
        cellStyle: { textAlign: 'left' }, headerClass: 'align-center',
        cellRenderer: function (params) { return '<span class="hover-shadow">' + params.value + '&nbsp;&nbsp; <i class="fa fa-clipboard" [cdkCopyToClipboard]=' + params.value + '></i> </span>' },
        tooltip: (params) => params.value,
      },
      {
        headerName: 'LIC No', field: 'LicenseNo', minWidth: 250,
        filterParams: { applyButton: true, clearButton: true },  filter: true, sortable: true,
        cellStyle: { textAlign: 'left' }, headerClass: 'align-center',
        cellRenderer: function (params) { return '<span class="hover-shadow">' + params.value + '&nbsp;&nbsp; <i class="fa fa-clipboard" [cdkCopyToClipboard]=' + params.value + '></i> </span>' },
        tooltip: (params) => params.value,
      },
      {
        headerName: 'Maximum Associates', field: 'Person', width: 250,
        filterParams: { applyButton: true, clearButton: true }, filter: true, sortable: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Issue Dt.', field: 'IssueDate', width: 150,
        filterParams: { applyButton: true, clearButton: true },  filter: true, sortable: true,
        cellStyle: { textAlign: 'left' }, headerClass: 'align-center',
        cellRenderer: (data) => {
          return moment(data.value).format('DD/MM/YYYY');
        },
      },
      {
        headerName: 'Validity Dt.', field: 'ValidtyDate', width: 150,
        filterParams: { applyButton: true, clearButton: true },  filter: true, sortable: true,
        cellStyle: { textAlign: 'left' }, headerClass: 'align-center',
        cellRenderer: (data) => {
          return moment(data.value).format('DD/MM/YYYY');
        },
      },
      {
        headerName: 'LIN NO', field: 'LinNumber', width: 200,
        filterParams: { applyButton: true, clearButton: true }, filter: true, sortable: true,
        cellStyle: { textAlign: 'left' }, headerClass: 'align-center'
      },
      {
        headerName: 'Nature of LIC', field: 'NatureOfLicense', width: 250,
        filterParams: { applyButton: true, clearButton: true }, filter: true, sortable: true,
        cellStyle: { textAlign: 'left' }, headerClass: 'align-center'
      },
      // {
      //   headerName: 'State', field: 'StateName', width: 250,
      //   headerComponentParams: { enableSorting: false }, suppressMenu: true,
      // },
      // {
      //   headerName: 'Status', field: 'Status', width: 250,
      //   headerComponentParams: { enableSorting: false }, sortable: true,
      //   cellStyle: { textAlign: 'left' }
      // },
      {
        headerName: 'Action', field: '', width: 100,
        sortable: false, suppressMenu: true, cellRendererFramework: AgActionCellRendererComponent
      }
    ];
    this.rowData = [];
    this.contractorType = 1;
  }
  ngOnInit() {

    const rolecode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
    if (rolecode === 119)
    // tslint:disable-next-line: brace-style
    {
      this.ForContractorLogin = true;
    }
    // tslint:disable-next-line: brace-style
    else
    // tslint:disable-next-line: brace-style
    {
      this.ForContractorLogin = true;
    }
    this.getLicence(this.contractorType);
  }

  getLicence(contractorType: number) {
    this.licenceService.getLicenses(contractorType).subscribe((res: []) => {
      if (res) {
        this.rowData = res;
      }
    });
  }
  onTabChange(event) {
    this.getLicence(event.nextId);
  }

  editLicence(rowData) {
    this.router.navigate([`/contractor/edit-licence/${rowData.License_Code}`]);
  }

  deleteLicence(rowData) {
    console.log(rowData);
  }
}
